/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import NavBar from "../components/layout/NavBar";
import { FiFilter } from "react-icons/fi";
import TransactionCard from "../components/Trade/TransactionCard";
import { ITradeTransaction } from "../types/trade";
import axios from "axios";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { Prosumer, selectProsumer } from "../redux/reducers/userReducer";
import { backend_url } from "../utils/be";
import { IonSkeletonText, useIonToast } from "@ionic/react";
import useEnergyVault from "../hooks/useEnergyVault";
import ProsumerDetailSection from "../components/Prosumer/ProsumerDetailSection";

const socketURL = "https://socket.dev.twinergy.transactiveenergymodule.com"

const TradeProsumerPage = () => {
  const { wallet } = useSelector(selectProsumer) as Prosumer
  const [transactions, setTransactions] = useState<ITradeTransaction[]>([]);
  const prosumer = useSelector(selectProsumer);
  const [toast] = useIonToast();
  const [orderBookData, setOrderBookData] = useState<any>();
  const [loadingListings, setLoadingListings] = useState(false)
  const [loadingTransactions, setLoadingTransactions] = useState(false)

  useEffect(() => {
    const socket = io(socketURL, {
      extraHeaders: {
        "ngrok-skip-browser-warning": true as any,
      }
    });
    socket.on("connect", () => console.log("Socket Connected!"));
    socket.on("connect_error", () => {
      setTimeout(() => socket.connect(), 5000);
    });
    setLoadingListings(true)
    socket.on("data", (data: any) => {
      setLoadingListings(false)
      let _data = JSON.parse(data)
      let buyOrders = _data?.buyOrders.allBuyOrders.filter((order: any) => {
        if (order?.buyer.toLowerCase() === wallet.toLowerCase()) {
          return {
            ...order,
            type: "buy"
          }
        } else {
          return null;
        }
      });
      console.log(_data?.sellOrders);
      let sellOrders = _data?.sellOrders.allSellOrders.filter((order: any) => {
        if (order?.seller.toLowerCase() === wallet.toLowerCase()) {
          return {
            ...order,
            type: "sell"
          }
        } else {
          return null;
        }
      });
      setOrderBookData([...buyOrders, ...sellOrders]);
    });
    socket.on("disconnect", () => console.log("Socket Disconnected!"));
    return () => {
      socket.disconnect();
    };
  }, []);


  useEffect(() => {
    getTransactions();
  }, [wallet]);

  async function getTransactions() {
    if (transactions.length > 0) return;
    // get all transactions
    setLoadingTransactions(true)
    await axios
      .get(`${backend_url}/user/transactions/${prosumer?.id}`)
      .then((res) => {
        if (res.data.success) {
          setTransactions(res.data.data);

        } else {
          toast({
            message: "Error occured while fetching transactions",
            color: "danger",
            duration: 4000,
            mode: "ios",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast({
          message: "Error occured while fetching transactions",
          color: "danger",
          duration: 4000,
          mode: "ios",
        });
      });
    setLoadingTransactions(false)
  }

  return (
    <>
      <NavBar
        t={2}
        l1="/account-data"
        l2="/readings"
        l3="/meters"
        l4="/balances"
        l5="/rewards"
        l6="/trades"
      />
      <ProsumerDetailSection />
      <Row>
        <div className="p-4 ">
          <section className="grid lg:grid-cols-2 gap-x-5 ">
            <aside className="p-3 shadow rounded-lg px-5">
              <div className="my-3 flex justify-between items-center font-medium text-xl">
                Listings
                <div className="flex items-center gap-x-2">
                  <Dropdown style={{ background: "transparent" }}>
                    <Dropdown.Toggle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "transparent",
                        outline: "none",
                        border: "none",
                        color: "black",
                      }}
                      name="manage button"
                    >
                      <FiFilter className="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        border: "1px solid var(--ion-color-success)",
                        fontSize: "13px",
                        color: "gray",
                      }}
                    >
                      <Dropdown.Item
                        href="/action-1"
                        style={{ padding: "3px 10px" }}
                      >
                        {" "}
                        <div className="border-b border-primaryGreen">
                          KWH high - low
                        </div>{" "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/action-2"
                        style={{ padding: "3px 10px" }}
                      >
                        <div className="border-b border-primaryGreen">
                          KWH low - high
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/action-3"
                        style={{ padding: "3px 10px" }}
                      >
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="border-b pb-2">
                <span className="text-gray-500 text-sm ">
                  All active listings
                </span>
              </div>
              <section className="mt-4">
                <div className="">
                  <div className="grid px-3 font-bold grid-cols-3 text-sm text-start w-full ">
                    <div className="">Kwh</div>
                    <div className="">Price</div>
                    {/* <div className="">Type</div> */}
                    <div className=''>Created</div>
                  </div>
                  <div>
                    {
                      !orderBookData && loadingListings && <div className=" px-3 border-b grid grid-cols-3 text-sm text-start w-full gap-3 my-6 ">
                        {
                          [1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => {
                            return (
                              <IonSkeletonText animated key={index} className="rounded-sm h-5"></IonSkeletonText>
                            )
                          })
                        }

                      </div>
                    }
                    {orderBookData?.map((item: any, index: number) => (
                      <tr
                        className="py-2 px-3 border-b grid grid-cols-3 text-sm text-start w-full "
                        key={index}
                      >
                        <div className="">{item.energyAmount}</div>
                        <div style={{
                          color: item.seller ? 'var(--ion-color-warning)' : 'var(--ion-color-success)'
                        }}>{item.total.toFixed(2)}</div>
                        <div className=''>{item.timestamp}</div>
                      </tr>
                    ))}
                  </div>
                </div>
              </section>
            </aside>
            <aside className="p-3 shadow rounded-lg px-5">
              <div className="my-3  flex justify-between items-center font-medium text-xl">
                Transactions
                <div className="flex items-center gap-x-2">
                  <Dropdown style={{ background: "transparent" }}>
                    <Dropdown.Toggle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "transparent",
                        outline: "none",
                        border: "none",
                        color: "black",
                      }}
                      name="manage button"
                    >
                      <FiFilter className="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        border: "1px solid var(--ion-color-success)",
                        fontSize: "13px",
                        color: "gray",
                      }}
                    >
                      <Dropdown.Item
                        href="/action-1"
                        style={{ padding: "3px 10px" }}
                      >
                        {" "}
                        <div className="border-b border-primaryGreen">
                          KWH high - low
                        </div>{" "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/action-2"
                        style={{ padding: "3px 10px" }}
                      >
                        <div className="border-b border-primaryGreen">
                          KWH low - high
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/action-3"
                        style={{ padding: "3px 10px" }}
                      >
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="border-b pb-2">
                <span className="text-gray-500 text-sm ">
                  All transactions{" "}
                </span>
              </div>
              <section className="mt-4">
                {
                  loadingTransactions && <div className=" px-3 border-b grid grid-cols-3 text-sm text-start w-full gap-3 my-6 ">
                    {
                      [1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => {
                        return (
                          <IonSkeletonText animated key={index} className="rounded-sm h-5"></IonSkeletonText>
                        )
                      })
                    }

                  </div>
                }
                {!loadingTransactions && transactions.map((item, index) => {
                  return <TransactionCard key={index} item={item} />;
                })}
              </section>
            </aside>
          </section >
        </div >
      </Row >
    </>
  );
};

export default TradeProsumerPage;
