import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import trgy_vault from "../contracts/trgy_vault.json";
import { toast } from "react-toastify";

const useTRGYVault = () => {
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const [trg_paused, setTRGPaused] = useState(false);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.trgy_vault, trgy_vault, signer));
    }
  }, [provider]);

  useEffect(() => {
    contract
      ?.paused()
      .then(data => {
        setTRGPaused(data);
      })
      .catch(err => console.error(err));
  }, [contract]);

  const getTRGYTradingVolume = async () => {
    let volume = await contract?.trgyTradingVolume();
    return Number(volume?._hex);
  };

  const pauseTRGYVault = async () => {
    try {
      await contract?.pauseVault();
      toast.success("TRG Vault Paused !!");
    } catch (error) {
      toast.error("Operation error !!");
    }
  };

  const unPauseTRGYVault = async () => {
    try {
      await contract?.unPauseVault();
      toast.success("TRG Vault Unpaused !!");
    } catch (error) {
      toast.error("Operation error !!");
    }
  };

  const blackListAddress = async address => {
    try {
      await contract?.blacklistAddress(address);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const removeBlackListAddress = async address => {
    try {
      console.log(address);
      await contract?.removeAddressFromBlacklist(address);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return {
    getTRGYTradingVolume,
    pauseTRGYVault,
    unPauseTRGYVault,
    trg_paused,
    setTRGPaused,
    blackListAddress,
    removeBlackListAddress
  };
};

export default useTRGYVault;
