import React, { useState } from "react";
import {
    Button,
    Dropdown,
} from "react-bootstrap";
import "./nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./nav.module.css";
import { useDispatch, useSelector } from "react-redux";
import useConnector from "../../hooks/useConnector";
import { useNavigate } from "react-router-dom";
import { IoExitOutline, IoKeyOutline, IoLockClosedOutline, IoSettingsOutline } from "react-icons/io5";
import { RootState } from "../../redux/store";
import { selectUser } from "../../redux/reducers/userReducer";
import SecurityModal from "../Modals/SecurityModal";

const NavSettingsSection = () => {
    const { connectWallet } = useConnector();
    const { address } = useSelector((state: RootState) => state.twinergy);
    const user = useSelector(selectUser);
    const dispatch = useDispatch()
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);


    const navigate = useNavigate();

    const logout = () => {
        sessionStorage.clear();
        window.sessionStorage.clear()
        localStorage.clear()
        navigate("/");
        window.location.reload();
    };

    const handleMouseEnter = (e: any) => {
        e.target.style.color = "#ffba4d";
    };

    const handleMouseLeave = (e: any) => {
        e.target.style.color = "white";
    };
    return (
        <>
            <Button
                onClick={connectWallet}
                size="sm"
                style={{ backgroundColor: "#7edd62", border: "none" }}
                name="connect-wallet"
            >
                {address ? `${address.slice(0, 7)}...${address.slice(38, 42)}` : <div className="flex gap-x-1">
                    <span className="hidden lg:block">Connect </span> <span>wallet</span>
                </div>}
            </Button>
            <span>
                <Dropdown
                    id="dropdown-basic"
                >
                    <Dropdown.Toggle
                        name="manage button"
                        className={styles.dtoggle}
                        variant="link"
                        id="dropdown-basic-toggle"
                    >
                        <IoSettingsOutline name="settings" className="text-yellow-500 text-xl" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className={styles.dmen}
                        style={{
                            backgroundColor: "#1e2727",
                            borderRadius: "0px 0px 0px 10px",
                        }}
                    >
                        <Dropdown.Item
                            className={styles.nditem}
                            style={{

                                backgroundColor: "#1e2727",
                                color: "#ffffff",
                                alignItems: "center",

                            }}
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                        >
                            <div className="flex gap-[10px]">
                                <IoSettingsOutline className="text-xl text-green-500" />
                                <label className="text-green-500">Setting</label>
                            </div>
                        </Dropdown.Item>
                        <div className="h-[1px] my-1 bg-green-500"></div>
                        <Dropdown.Item
                            className={styles.nditem}
                            onClick={() => setShow(true)}
                            style={{
                                backgroundColor: "#1e2727",
                                color: "#ffffff",
                                alignItems: "center",

                            }}
                        >
                            <div className="flex gap-[10px] hover:text-yellow-500">
                                <IoLockClosedOutline className="text-xl" />
                                <span >
                                    Security
                                </span>
                            </div>
                        </Dropdown.Item>
                        <div className="h-[1px] my-1 bg-green-500"></div>

                        <Dropdown.Item
                            className={styles.nditem}
                            style={{
                                backgroundColor: "#1e2727",
                                color: "#ffffff",
                                alignItems: "center",

                            }}
                            // onMouseEnter={handleMouseEnter}
                            // onMouseLeave={handleMouseLeave}
                            onClick={() => logout()}
                        >
                            <div className="flex gap-[10px] hover:text-yellow-500">
                                <IoExitOutline className="text-xl" />
                                <label>Logout</label>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </span >
            <span className="user hidden lg:block">
                <span className="name">{user?.name || user?.email}</span>
            </span>
            <SecurityModal handleClose={handleClose} show={show} />

        </>
    )
}

export default NavSettingsSection