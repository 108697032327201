import axios from "axios";
import { AdminRole, IAdmin, IAdminRoleAPI } from "../types/admin";
import { backend_url } from "../utils/be";
import { useIonToast } from "@ionic/react";
import { selectAdminRoles, selectUser, setAdminRoles, setAdmins } from "../redux/reducers/userReducer";
import { APIDataToSelectedRoles } from "../utils/admin";
import { useDispatch, useSelector } from "react-redux";


export function useAdminAPI() {
    const [toast] = useIonToast()
    const dispatch = useDispatch();
    const adminRoles = useSelector(selectAdminRoles)
    const user = useSelector(selectUser)

    async function createAdminAccount({ name, email, password, role }: { name: string, email: string, password: string, role: string }) {
        axios
            .post("/admin/register", { name, email, password, role })
            .then((res) => {
                if (res.data.success) {
                    getAllAdmins();
                    return true
                } else {
                    toast({ message: res.data.message, duration: 2000, color: 'danger' })
                }
            })
            .catch((err) => {
                toast({
                    message: "Failed to create admin account. Please try again!",
                    duration: 4000,
                    color: "danger",
                    buttons: [{ text: "OK" }],
                });
            });
    }

    async function addAdminRole(role: AdminRole) {
        return axios.post(`${backend_url}/admin/create_role`, role).then((res) => {
            if (res.data.success) {
                toast({ message: "Successfully Created Role", duration: 2000, color: 'success' });
                return true;
            } else {
                toast({ message: res.data.message, duration: 2000, color: 'warning' });
            }
        }).catch((err) => {
            toast({ message: "Role creation failed. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
        })
    }

    async function getAdminRoles() {
        return axios.get(`${backend_url}/admin/get_roles`).then((res) => {

            if (res.data.success) {
                const roles = res.data.data as IAdminRoleAPI[];
                if (roles) {
                    const newRoles = roles.map((role) => APIDataToSelectedRoles(role));
                    const allRoles = newRoles.map((role) => ({
                        title: role.title,
                        id: role.id,
                        isSuperAdmin: role.isSuperAdmin,
                        selectedRoles: role.selectedRoles.filter((role) => role.value == "EDIT" || role.value == "VIEW")
                    }));
                    dispatch(setAdminRoles(allRoles));
                }
            } else {
                toast({ message: res.data.message, duration: 2000, color: 'warning' });
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status == 401 || status == 403) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/";
            }
            console.log(err)
            toast({ message: "Failed to get admin role. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
        })
    }

    async function editAdminRoleById(roleId: string, id: string) {
        return axios.put(`${backend_url}/admin/update/${id}`, { role: roleId, changes: ['role'] }).then((res) => {
            if (res.data.success) {
                toast({ message: "Edit successfull!", duration: 2000, color: 'success' });
                return true;
            } else {
                toast({ message: res.data.message, duration: 2000, color: 'warning' });
            }
        }).catch((err) => {
            console.log(err)
            toast({ message: "Failed to edit admin role. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
        })
    }

    async function editAdminData(admin: IAdmin, id: string) {
        return axios.put(`${backend_url}/admin/update/${id}`, { ...admin, changes: Object.keys(admin) }).then((res) => {
            if (res.data.success) {
                toast({ message: "Edit successfull!", duration: 2000, color: 'success' });
                return true;
            } else {
                toast({ message: res.data.message, duration: 2000, color: 'warning' });
            }
        }).catch((err) => {
            console.log(err)
            toast({ message: "Failed to edit admin role. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
        })
    }


    async function editAdminRole(role: AdminRole, id: string) {
        return axios.put(`${backend_url}/admin/update_role/${id}`, { ...role }).then((res) => {
            if (res.data.success) {
                toast({ message: "Edit successfull!", duration: 2000, color: 'success' });
                return true;
            } else {
                toast({ message: res.data.message, duration: 2000, color: 'warning' });
            }
        }).catch((err) => {
            console.log(err)
            toast({ message: "Failed to edit admin role. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
        })

    }
    // /admin/delete_role/:id

    async function deleteAdminRole(id: string) {
        return axios.delete(`${backend_url}/admin/delete_role/${id}`).then((res) => {
            if (res.data.success) {
                // console.log(res.data)
                // return res.data.data as IAdminRoleAPI[]
            } else {
                toast({ message: res.data.message, duration: 2000, color: 'warning' });
            }
        }).catch((err) => {
            console.log(err)
            toast({ message: "Failed to delete admin role. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
        })
    }

    async function getAllAdmins() {
        return axios
            .get("/admin/admins")
            .then((res) => {

                dispatch(setAdmins(res.data.data));
            })
            .catch((err) => {
                toast({
                    message: "Failed to get admins. Please try again!",
                    duration: 4000,
                    color: "danger",
                    buttons: [{ text: "OK" }],
                });
            });
    }

    function adminIsPermitted(roleId: string, type?: "EDIT" | "VIEW" | "NONE") {
        const thisAdminRole = adminRoles.find(role => role.id === user?.role)?.selectedRoles ?? []
        const isAllowed = thisAdminRole.find((role) => {
            if (role.id == roleId) {
                if (type) {
                    return role.value == type
                }
                return true
            }
        })
        return isAllowed && adminRoles.length > 0
    }


    return {
        createAdminAccount,
        addAdminRole,
        getAdminRoles,
        editAdminRole,
        deleteAdminRole,
        editAdminRoleById,
        getAllAdmins,
        editAdminData,
        adminIsPermitted
    }
}