import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBan, faPause } from "@fortawesome/free-solid-svg-icons";
import "./sidebar.css";
import { Image } from "react-bootstrap";
import Footer from "./Footer";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";
import { IoPauseOutline, IoPeopleOutline } from "react-icons/io5";
import { BsDatabase } from "react-icons/bs"
import { FiBarChart2, FiPause } from "react-icons/fi"
import { AiOutlineGift } from "react-icons/ai";
import { GiTrade } from "react-icons/gi";
import { useSelector } from "react-redux";
import { selectAdminRoles, selectUser } from "../../redux/reducers/userReducer";
import { useAdminAPI } from "../../api/admin.api";
import { FaTable } from "react-icons/fa";

function SideBar({ children }: { children: any }) {

  let location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const user = useSelector(selectUser)
  const { adminIsPermitted } = useAdminAPI()

  const menuItem = [
    {
      path: "/#/",
      name: "Prosumers",
      roleId: "prosumerProfile",
      icon: (
        <IoPeopleOutline />
      ),
    },
    {
      path: "/#/tokenomics",
      name: "Tokenomics",
      roleId: "tokenomics",
      icon: (<BsDatabase />
      ),
    },
    {
      path: "/#/LEM-price",
      name: "LEM price",
      roleId: "lemPrice",
      icon: (
        <svg
          className="svg"
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1V4"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 13V16"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.69751 3.19745L5.82001 5.31995"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.1799 11.68L14.3024 13.8025"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.5 8.5H4.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.5 8.5H16.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.69751 13.8025L5.82001 11.68"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.1799 5.31995L14.3024 3.19745"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      path: "/#/blacklist",
      name: "Blacklist",
      roleId: "blacklistWallets",
      icon: <FontAwesomeIcon icon={faBan} />,
    },


    {
      path: "/#/pause-contracts",
      name: "Pause contracts",
      roleId: "pauseContracts",
      icon: <FiPause />,

    },
    {
      path: "/#/DER-rewards",
      name: "DER Rewards",
      roleId: "derRewards",
      icon: <AiOutlineGift />,
    },
    {
      path: "/#/admins",
      name: "Admins",
      roleId: "admins",
      icon: (
        <svg
          className="svg"
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.1973 1L14.5918 2.57687M14.5918 2.57687L17 4.94217L14.1904 7.70169L11.7822 5.33639M14.5918 2.57687L11.7822 5.33639M8.48301 8.57685C8.89749 8.97853 9.22698 9.45677 9.45251 9.98404C9.67804 10.5113 9.79515 11.0772 9.7971 11.6491C9.79905 12.221 9.6858 12.7876 9.46388 13.3164C9.24195 13.8451 8.91573 14.3255 8.50399 14.7299C8.09226 15.1343 7.60315 15.4547 7.06482 15.6727C6.52649 15.8907 5.94958 16.0019 5.36731 16C4.78503 15.9981 4.20889 15.883 3.67206 15.6615C3.13523 15.44 2.64832 15.1164 2.23935 14.7093C1.43512 13.8914 0.990108 12.7961 1.00017 11.6591C1.01023 10.5221 1.47455 9.43446 2.29313 8.63046C3.11171 7.82646 4.21906 7.37041 5.37667 7.36053C6.53427 7.35065 7.64952 7.78773 8.4822 8.57764L8.48301 8.57685ZM8.48301 8.57685L11.7822 5.33639"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),

    },
    {
      path: "/#/admin-trades",
      name: "Trade",
      roleId: "tradeSettings",
      icon: <FiBarChart2 />,
    },
    {
      path: "/#/logs",
      name: "Logs",
      roleId: "logs",
      icon: <FaTable />,
    },
  ];
  return (
    <div className="contai ">
      <div className={`sbsidebar w-[50px] ${isOpen ? "md:w-[250px]" : "md:w-[50px]"} `}>
        <div className="sbtop_section">
          <h1 style={{ display: isOpen ? "block" : "none" }} className="sblogo">
            <Image src={require("./../../asserts/images/logo.svg").default} alt="twinergy logo" />
          </h1>
          <div
            style={{ marginLeft: isOpen ? "50px" : "0px" }}
            className="sbbars"
          >
            <FontAwesomeIcon icon={faBars} onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => {

          const pathMatches = location?.pathname.replace("\/#", "") === item.path.replace("\/#", "");
          if (!adminIsPermitted(item.roleId)) return <div key={index}></div>
          return (
            <a
              href={item.path}
              key={index}
              className={
                "sblink mb-3 font-light border-r-[3px] " + (pathMatches ? "text-primaryGreen border-primaryGreen " : "border-transparent")
              }
            >
              <div className="sbicon mr-3">{item.icon}</div>
              <div
                className={`sblink_text hidden ${isOpen ? "md:block" : "md:hidden"} `}
              >
                {item.name}
              </div>
            </a>
          )
        })}
        {/* {isOpen ? (
          <div className="sbfooter">
            <Footer />
          </div>
        ) : null} */}
      </div>
      <main>{children}</main>
    </div>
  );
}

export default SideBar;
