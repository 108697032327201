import { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { MeterReadings } from "../../types/Prosumer";
import TableSkeleton from "../layout/tableSkeleton";

const ReadingsTable = ({ data, rowsPerPage, loading }: { data: MeterReadings[], rowsPerPage: number, loading: boolean }) => {
  const [row_num, setrow_num] = useState(10);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, row_num) as { slice: MeterReadings[], range: any };

  useEffect(() => {
    setPage(page + 1);
  }, [row_num]);
  return (
    <Row style={{ width: "100%" }}>
      <div className="rshow text-right pr-3">
        Show{" "}
        <Form.Select
          aria-label="Select number of records"
          style={{
            width: "73px",
            height: "35px",
            display: "inline-block",
          }}
          className="ml-2 mr-2"
          // iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
          value={row_num}
          onChange={(e) => setrow_num(+e.target.value)}
        >
          <option value={10}>10</option>
          <option value={2}>25</option>
          <option value={50}>50</option>
        </Form.Select>
        Records
      </div>
      <table className={styles.table} style={{ width: "100%" }}>
        <thead className={styles.tableRowHeader}>
          {/* <tr> */}
          <th className={styles.tableHeader}>Manage</th>
          <th className={styles.tableHeader}>Type</th>
          <th className={styles.tableHeader}>Description</th>
          <th className={styles.tableHeader}>Input Readings</th>
          <th className={styles.tableHeader}>Output Readings</th>

          {/* </tr> */}
        </thead>
        <tbody style={{ width: "100%" }}>
          {slice.map((el) => (
            <tr
              style={{ width: "100%" }}
              className={styles.tableRowItems}
              key={el.id}
            >
              <td className={styles.tableCell}>
                <Dropdown className="drop">
                  <Dropdown.Toggle
                    style={{
                      cursor: "pointer",
                      background: "transparent",
                      color: "black",
                      border: "0px",
                      outline: "none",
                    }}
                    className={styles.dtoggle}
                    name="manage button"
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>View</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td className={styles.tableCell}>{el.dwelling_identifier.replace("meter", "")}</td>
              <td className={styles.tableCell}>{el.description}</td>
              <td className={styles.tableCell}>{el.input}</td>
              <td className={styles.tableCell}>{el.output}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <TableSkeleton number={5} />}
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </Row >
  );
};

export default ReadingsTable;
