/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { updateAddress } from "../redux/reducers/twinergyReducer";
import { useNavigate } from "react-router-dom";

function useConnector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.replace("/");
      });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    } else {
      if (
        window.confirm("Please click OK to install metamask on your browser.")
      ) {
        window.location.href = "https://metamask.io/download";
      }
    }
  });

  useEffect(() => {
    // check Connection
    checkConnection();
    // Set Provider
    setProvider(new ethers.providers.Web3Provider(window?.ethereum));
  }, []);

  useEffect(() => {
    // Check Network
    checkNetwork();
  }, [provider]);

  function checkConnection() {
    window?.ethereum
      .request({ method: "eth_accounts" })
      .then(handleAccountsChanged)
      .catch(console.error);
  }

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0 && window.location.pathname !== "/") {
      dispatch(updateAddress(""));
    } else {
      dispatch(updateAddress(accounts[0]));
      checkNetwork();
    }
  }

  const checkNetwork = async () => {
    try {
      const network = await provider.getNetwork();
      if (network.chainId !== 4002) return navigate("/error");
    } catch (error) {}
  };

  const connectWallet = async () => {
    provider
      .send("eth_requestAccounts")
      .then(async result => {
        localStorage.setItem("baki_user", result[0]);
        dispatch(updateAddress(result[0]));
      })
      .catch(error => {
        console.error(error);
      });
  };
  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: 4002 }],
      });
    } catch {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xfa2",
            rpcUrls: ["https://rpc.testnet.fantom.network/"],
            chainName: "Fantom testnet",
            nativeCurrency: {
              name: "FTM",
              symbol: "FTM",
              decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.ftmscan.com"],
          },
        ],
      });
    }
  };

  const getFTMBalance = async (address) => {
    // Get the balance of the address
    const balance = await provider?.getBalance(address);
    // Convert balance to Ether
    const balanceEth = ethers.utils.formatEther(balance);
    return +balanceEth;
  }

  return {
    switchNetwork,
    connectWallet,
    provider,
    getFTMBalance
  };
}
export default useConnector;
