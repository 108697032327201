import React, { useState, useEffect } from "react";
import { Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { toast } from "react-toastify";
import axios from "axios";
import { copyAddress } from "../../utils/clipboard";
import useTRGYVault from "../../hooks/useTRGYVault";
import { useAdminAPI } from "../../api/admin.api";

const BlacklistTable = ({ data, rowsPerPage, refreshList }) => {
  const [row_num, setrow_num] = useState(10);
  const { removeBlackListAddress } = useTRGYVault();
  const [page, setPage] = useState(1);
  const [blacklist, setBlacklist] = useState([]);
  const { slice, range } = useTable(data, page, row_num);
  const { adminIsPermitted } = useAdminAPI()
  const isPermitted = !!adminIsPermitted("blacklistWallets", "EDIT")

  useEffect(() => {
    setPage(page + 1);
  }, [row_num]);
  useEffect(() => {
    fetchBlacklist();
    refreshList.current = fetchBlacklist;
  }, []);
  const fetchBlacklist = () => {
    console.log("fetching...")
    if (isPermitted) console.error({ message: "You are not permitted to perform this function" })
    axios
      .get("/admin/blacklist_addresses")
      .then((res) => {
        console.log(res.data)
        setBlacklist(res.data.data.reverse());
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleRemoveBlacklist = async (element) => {
    let result = await removeBlackListAddress(element?.address);
    if (result) await removeBlacklist(element?.id);
    if (result) {
      toast.success("Operation Successful");
    } else {
      toast.error("Operation Failed");
    }

  };
  const removeBlacklist = async (id) => {
    axios
      .post(`/admin/remove_blacklist_add/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        fetchBlacklist();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <Row style={{ width: "100%" }}>
      <div className="rshow text-right pr-3">
        Show{" "}
        <Form.Select
          aria-label="Select number of records"
          style={{
            width: "73px",
            height: "35px",
            display: "inline-block",
          }}
          className="ml-2 mr-2"
          iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
          value={row_num}
          onChange={(e) => setrow_num(e.target.value)}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </Form.Select>
        Records
      </div>
      <table className={styles.table} style={{ width: "100%" }}>
        <thead className={styles.tableRowHeader}>
          {/* <tr> */}
          <th className={styles.tableHeader}>Manage</th>
          <th className={styles.tableHeader}>Timestamp</th>

          <th className={styles.tableHeader}>Wallet address</th>
          <th className={styles.tableHeader}>Blacklisted by</th>
          {/* </tr> */}
        </thead>
        <tbody style={{ width: "100%" }}>
          {blacklist.map((el) => (
            <tr
              style={{ width: "100%" }}
              className={styles.tableRowItems}
              key={el.id}
            >
              <td className={styles.tableCell}>
                <Dropdown className="drop">
                  <Dropdown.Toggle
                    disabled={!isPermitted}
                    style={{
                      cursor: "pointer",
                      background: "transparent",
                      color: "black",
                      border: "0px",
                      outline: "none",
                    }}
                    className={styles.dtoggle}
                    name="manage button"
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item disabled={!isPermitted} onClick={() => handleRemoveBlacklist(el)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td className={styles.tableCell}>{el.createdAt}</td>

              <td className={styles.tableCell} style={{ position: "relative" }}>
                {`${el.address?.slice(0, 5)}...${el.address?.slice(38, 42)}`}{" "}
                <FontAwesomeIcon
                  icon={faCopy}
                  className={styles.copy_icon}
                  onClick={() => copyAddress(el.address)}
                />
              </td>
              <td className={styles.tableCell}>{el.admin_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </Row >
  );
};

export default BlacklistTable;
