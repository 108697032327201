import React, { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";
import { FaCopy, FaEllipsisV } from "react-icons/fa";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { RewardHistory } from "../../types/Rewards";
import TableSkeleton from "../layout/tableSkeleton";

const DERProsumerTable = ({ data, rowsPerPage, loading }: { data: RewardHistory[], rowsPerPage: number, loading: boolean }) => {
    const [row_num, setrow_num] = useState(10);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, row_num) as { slice: RewardHistory[], range: number[] };

    function convertTimestamp(timestamp: string) {
        const [date, time] = timestamp.split("T")
        const finalTime = time.split(":")[0] + ":" + time.split(":")[1]
        return date.split("-").reverse().join("-") + " " + finalTime
    }

    useEffect(() => {
        setPage(page + 1);
    }, [row_num]);
    return (
        <Row style={{ width: "100%" }}>
            <div className="rshow text-right pr-3">
                Show{" "}
                <Form.Select
                    aria-label="Select number of records"
                    style={{
                        width: "73px",
                        height: "35px",
                        display: "inline-block",
                    }}
                    className="ml-2 mr-2"
                    //   iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
                    value={row_num}
                    onChange={(e) => setrow_num(+e.target.value!)}
                >
                    <option value={10}>10</option>
                    <option value={2}>25</option>
                    <option value={50}>50</option>
                </Form.Select>
                Records
            </div>
            <div className="w-full">
                <table className={styles.table} style={{ width: "100%" }}>
                    <thead style={{ width: "100%" }} >
                        {/* <tr> */}
                        <th className={styles.tableHeader}>Title</th>
                        <th className={styles.tableHeader}>Description</th>
                        <th className={styles.tableHeader}>Price</th>
                        <th className={styles.tableHeader}>Status</th>
                        <th className={styles.tableHeader}>Claimed</th>
                        <th className={styles.tableHeader}>Issued</th>

                        {/* </tr> */}
                    </thead>
                    <tbody style={{ width: "100%" }}>
                        {slice.map((el) => (
                            <tr
                                style={{ width: "100%" }}
                                className={styles.tableRowItems}
                                key={el.id}
                            >

                                <td className={styles.tableCell}>{el.title}</td>
                                <td className={styles.tableCell} style={{ position: "relative", display: "flex", }}>
                                    {el.description}
                                    <FaCopy className="ml-2" />
                                </td>
                                <td className={styles.tableCell}>{el.price} DRR</td>
                                <td className={styles.tableCell}>{el.status}</td>
                                <td className={styles.tableCell}>{convertTimestamp(el.createdAt)}</td>
                                <td className={styles.tableCell}>{el.issued_date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {loading && <TableSkeleton number={5} />}
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </Row>
    );
};

export default DERProsumerTable;
