import React, { useState } from "react";
import {
    Button,
    Col,
    Row,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import "../layout/nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../layout/nav.module.css";
import { NavLink } from "react-router-dom";
import { IonSegment, IonSegmentButton, IonToolbar } from "@ionic/react";
import ChangePasswordSegment from "../layout/security/ChangePasswordSegment";
import TwoFaAuthSetting from "../layout/security/TwoFaAuthSetting";


interface ModalProps {
    show: boolean;
    handleClose: () => void;
}

const SecurityModal: React.FC<ModalProps> = ({ show, handleClose }) => {
    const [tabStatus, setTabStatus] = useState<"CP" | "2FA">("CP")


    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal"
            style={{ borderRadius: "10px" }}
        >
            <Modal.Header style={{ position: "relative" }} className={styles.mhead}>
                <Modal.Title>Security</Modal.Title>
                <div className={styles.customeClose} onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                </div>

            </Modal.Header>
            <div className="mb-4 bg-gray-50">
                <IonSegment value={tabStatus} onIonChange={(e) => setTabStatus(e.detail.value as any)}>
                    <IonSegmentButton value={"CP"}>Change Password</IonSegmentButton>
                    <IonSegmentButton value={"2FA"}>2FA</IonSegmentButton>
                </IonSegment>
            </div>
            <Modal.Body>
                <div className="pb-5">
                    <div>
                        {tabStatus == "CP" ? <ChangePasswordSegment /> : <TwoFaAuthSetting />}
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default SecurityModal;