export default [
  {
    id: 1,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 2,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },

  {
    id: 3,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 4,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 5,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 6,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 7,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 8,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 9,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
  {
    id: 10,
    Timestamp: "2021-09-01 00:00:00",
    WalletAddress: "0x9541...36wg",
    by: "Andrea Philips ",
  },
];
