import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import "./../styles/prosumer.css";
import MetersTable from "../components/Table/MetersTable";
import NavBar from "../components/layout/NavBar";
import axios from "axios";
import { backend_url } from "../utils/be";
import { IMeter } from "../types/Prosumer";
import { useSelector } from "react-redux";
import { selectProsumer } from "../redux/reducers/userReducer";
import useConnector from "../hooks/useConnector";
import ProsumerDetailSection from "../components/Prosumer/ProsumerDetailSection";
import { IonButton, IonIcon } from "@ionic/react";
import { IoAdd } from "react-icons/io5";
import AddMeterButton from "../components/layout/prosumer/AddMeterButton";

export default function MetersPage() {
  //create and initialize an array of prosumers with a tuple of 6 elements
  const [meters, setMeters] = useState<IMeter[]>([]);
  const prosumer = useSelector(selectProsumer);
  const { getFTMBalance, provider }: any = useConnector();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getMeters()
  }, [provider]);

  function getMeters() {
    setLoading(true)
    axios
      .get(`${backend_url}/meter/get_meters/${prosumer?.id}`)
      .then(async (response) => {
        const meters = response.data as IMeter[];
        let _meters: any = [];
        console.log(meters)

        for (let meter of meters) {
          try {
            _meters.push({
              ...meter,
              FTMBalance: await getFTMBalance(meter.wallet_address),
            });
          } catch (err) {
            _meters.push({
              ...meter
            });
          }
        }

        setMeters(_meters);
      }).finally(() => {
        setLoading(false)
      });
  }

  return (
    <React.Fragment>
      <NavBar
        t={2}
        l1="/account-data"
        l2="/readings"
        l3="/meters"
        l4="/balances"
        l5="/rewards"
        l6="/trades"
      />
      <ProsumerDetailSection />
      <Container style={{ padding: 15 }}>
        <Row>
          <div>
            <AddMeterButton onSuccess={() => getMeters()} />
          </div>
        </Row>
        <Row className="tcontainer">
          <Col xs={12} className="table_col">
            <div className="tcont1">
              <div className="ttitle">Meters</div>
              <div className="tcard">
                <MetersTable loading={loading} data={meters} rowsPerPage={4} />
              </div>
            </div>
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  );
}
