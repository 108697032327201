import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import admins from '../data/admins'
import AdminTable from "../components/Table/AdminsTable";
import NavBar from '../components/layout/NavBar';
import DERProsumerTable from '../components/Table/DERProsumerTable';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DRRReward, RewardHistory } from '../types/Rewards';
import { der_baseurl } from './DERRewards';
import { useSelector } from 'react-redux';
import { selectProsumer } from '../redux/reducers/userReducer';
import ProsumerDetailSection from '../components/Prosumer/ProsumerDetailSection';


const RewardsProsumerPage = () => {
    const [createReward, setCreateReward] = useState(false)
    const [queryTitle, setQueryTitle] = useState("")
    const [queryStatus, setQueryStatus] = useState("All")
    const [rewards, setRewards] = useState<RewardHistory[]>([])
    const [tempRewards, setTempRewards] = useState<RewardHistory[]>([])
    const prosumer = useSelector(selectProsumer);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getRewards()
    }, [])

    async function getRewards() {
        const headers: any = {
            'ngrok-skip-browser-warning': true,
        }
        if (!prosumer?.id) return;

        setLoading(true)
        axios.get(`${der_baseurl}/get_history/${prosumer?.id}`, { headers }).then((res) => {
            if (res.data.success) {
                setRewards(res.data.data || [])
                setTempRewards(res.data.data || [])
            }
        }).catch((err) => {
            toast.error(err.message);
        }).finally(() => {
            setLoading(false)
        });
    }

    function filterRewards() {
        let temp = tempRewards.filter((reward) => {
            if (queryStatus === "All") {
                return reward.title.toLowerCase().includes(queryTitle.toLowerCase())
            } else {
                return reward.title.toLowerCase().includes(queryTitle.toLowerCase()) && reward.status === queryStatus
            }
        })
        setRewards(temp)

    }

    return (
        <>
            <NavBar t={2} l1="/account-data" l2="/readings" l3="/meters" l4="/balances" l5="/rewards" l6="/trades" />
            <ProsumerDetailSection />
            <Row >
                <div className="mt-10"  >
                    <div className="grid lg:grid-cols-2 gap-y-5">
                        <div className="flex items-center space-x-3 text-gray-500 font-light">
                            <div>
                                <label >Title</label>
                                <div className='mt-2'>
                                    <input value={queryTitle} onChange={(e) => setQueryTitle(e.target.value)}
                                        type="text" className='border w-48 rounded-xl outline-yellow-500 py-2 px-2' />
                                </div>
                            </div>
                            <div>
                                <label >Status</label>
                                <div className='mt-2   border rounded-xl font-light w-48 outline-yellow-500 py-2 px-2'>
                                    <select value={queryStatus} onChange={(e) => setQueryStatus(e.target.value)}
                                        className='w-100 outline-none' >
                                        <option value="All">All</option>
                                        <option value="open">Open</option>
                                        <option value="close">Close</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex  lg:justify-end '>
                            <div className='relative flex flex-col justify-between'>
                                <div></div>
                                <div className=' w-100 space-x-3'>
                                    <button name='search' onClick={filterRewards} className='rounded-md bg-green-500 p-[10px] text-white w-32 '>
                                        Search
                                    </button>
                                    <button name='create' onClick={() => setCreateReward(true)} className='rounded-md bg-green-500 p-[10px] text-white w-32 '>
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Col xs={12} className="table_col" style={{ borderRadius: "20px" }}>
                    <div className="ttitle py-3">Rewards</div>
                    <div className="tcont1">
                        <div className="tcard">
                            <DERProsumerTable data={rewards} rowsPerPage={4} loading={loading} />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default RewardsProsumerPage