import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styles from "./../styles/lem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import NavBar from "../components/layout/NavBar";
import axios from "axios";
import { backend_url } from "../utils/be";
import { IonSelect, IonSpinner } from "@ionic/react";
import { convertTimeAMPM } from "../api/timeDate";


interface LemPriceData {
  id: number;
  timestamp: string;
  hour_index: string;
  bristol: string;
  hagedorn: string;
  benetutti: string;
  dayHour: string;
}

export default function BrisLEMPricePage() {

  const [data, setData] = useState<Partial<LemPriceData>[]>([]);
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState("day")

  useEffect(() => {
    getLemPriceFilter();
  }, [filter])

  function getLemPriceFilter() {
    const backend_url = `https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/lem/lem_price/filter/${filter}`
    const newLocal = `${backend_url}`;
    setLoading(true);


    if (filter === "day") {
      getLemPriceToday()
      return
    }

    axios.get(newLocal)
      .then(function (response) {
        if (response.data.success) {

          console.log(response.data.data);
          const prices = (response.data.data) as LemPriceData[]
          let result = prices.reverse().map((p) => ({ ...p, dayHour: p.timestamp.split("/")[0] + "th/" + p.hour_index + " hr" }))
          if (filter == "month") {
            result = result.map((p, i) => {
              return { ...p, dayHour: `${convertTimeAMPM(+p.hour_index)}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}` }

            })
          }
          else if (filter == "week") {
            result = result.map((p, i) => {
              return { ...p, dayHour: `${convertTimeAMPM(+p.hour_index)}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}` }

            })
          }
          setData(result)
          // console.log(result)
        } else {
          console.log("error")
        }
        setLoading(false)

      }
      )
      .catch(function (error) {
        console.log(error);
        setLoading(false)

      }
      )
  }
  function getLemPriceToday() {
    const backend_url = "https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/lem"
    const newLocal = `${backend_url}/all_lem_price`;
    setLoading(true);

    axios.get(newLocal)
      .then(function (response) {
        if (response.data.success) {
          const prices = (response.data.data) as LemPriceData[]

          setData(prices.
            filter((price, index) => {
              const day = price.timestamp.split("/")[0]
              //if day not today
              if (index >= prices.length - 24) return true;
              return false;
            }).map((p) => ({ ...p, dayHour: convertTimeAMPM(+p.hour_index) }))
            .reverse())

          // console.log(response.data.data)
        } else {
          console.log("error")
        }
        setLoading(false)

      }
      )
      .catch(function (error) {
        console.log(error);
        setLoading(false)

      }
      )

  }





  return (
    <React.Fragment>
      <NavBar
        t={1}
        l1="/LEM-price"
        l2="/LEM-price-Benetutti"
        l3="/LEM-price-Bristol"
      />
      <Row className="pl-3 ml-3 overflow-hidden">
        <Row className={`${styles.hcont}`}>
          <Row className={styles.Grow}>
            <Col xs={12} lg={2}>
              <div>
                <select className={"mb-2"} value={filter} onChange={(e) => setFilter(e.target.value)}>
                  <option value={"day"}>Day</option>
                  <option value={"week"}>Week</option>
                  <option value={"month"}>Month</option>
                </select>
              </div>
              <div className={styles.kcont}>
                <div className={styles.withprice}>
                  <div className={styles.price}>€{data[data.length - 1]?.benetutti ?? 0}</div>
                  {/* <div className={styles.unprice}>
                    LEM Price
                    <span className={styles.uprice}>
                      <svg
                        width="10"
                        height="5"
                        viewBox="0 0 10 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.upicon}
                      >
                        <path
                          d="M0.869629 4.35122L5.06217 0.612061L9.25472 4.35122H0.869629Z"
                          fill="#BCCCCC"
                        />
                      </svg>
                      +2.45%
                    </span>
                  </div> */}
                </div>
                <div className={styles.key}>
                  <div className={styles.kprojected}>
                    <span className={` w-2 h-2 rounded-full mr-2 bg-[#ffba4d]`}></span> Benetuti LEM price /
                    current
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={10}>
              {loading && <IonSpinner />}
              <AreaChart
                width={window.innerWidth - window.innerWidth / 100 * 35}
                height={window.innerHeight / 2.5}
                data={data}
                className="text-sm"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis xAxisId="0" dataKey="dayHour" />
                {filter !== "day" && <XAxis xAxisId="1" dataKey="otherIndex" allowDuplicatedCategory={false} />}

                <YAxis type="number" domain={[0, 0.7]} />
                <Tooltip />
                <Tooltip label={"hour_index"} />
                {filter !== "day" && <XAxis xAxisId="1" dataKey="otherIndex" allowDuplicatedCategory={false} />}



                <Area
                  type="monotone"
                  dataKey="benetutti"
                  stackId="1"
                  stroke="#ffba4d"
                  fill="#ffba4d"
                />
              </AreaChart>
            </Col>
            <span className={styles.tbars}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 4.2H1.6C2.37 4.2 3 4.83 3 5.6V12.6C3 13.37 2.37 14 1.6 14H1.4C0.63 14 0 13.37 0 12.6V5.6C0 4.83 0.63 4.2 1.4 4.2ZM7 0C7.77 0 8.4 0.63 8.4 1.4V12.6C8.4 13.37 7.77 14 7 14C6.23 14 5.6 13.37 5.6 12.6V1.4C5.6 0.63 6.23 0 7 0ZM12.6 8C13.37 8 14 8.63 14 9.4V12.6C14 13.37 13.37 14 12.6 14C11.83 14 11.2 13.37 11.2 12.6V9.4C11.2 8.63 11.83 8 12.6 8Z"
                  fill="#8F9BBA"
                />
              </svg>
            </span>
          </Row>
        </Row>
      </Row>
    </React.Fragment>
  );
}
