import React, { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { IMeter } from "../../types/Prosumer";
import TableSkeleton from "../layout/tableSkeleton";
import CreateUpdateMeterModal from "../Modals/AddMeterModal";



const MetersTableItem: React.FC<{ meter: IMeter, }> = ({ meter, }) => {

    const [editMeter, setEditMeter] = useState(false)

    return (
        <> <tr
            style={{ width: "100%" }}
            className={styles.tableRowItems}
            key={meter.id}
        >
            <td className={styles.tableCell}>
                <Dropdown className="drop">
                    <Dropdown.Toggle
                        style={{
                            cursor: "pointer",
                            background: "transparent",
                            color: "black",
                            border: "0px",
                            outline: "none",
                        }}
                        className={styles.dtoggle}
                        name="manage button"
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item onClick={() => setEditMeter(true)}>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
            <td className={styles.tableCell}>{meter.dwelling_identifier}</td>
            <td className={styles.tableCell}>{meter.SN}</td>
            <td className={styles.tableCell} style={{ position: "relative" }}>
                {meter.wallet_address?.substring(0, 4) + "..." + meter.wallet_address?.substring(meter.wallet_address.length - 4, meter.wallet_address.length)}
                <FontAwesomeIcon icon={faCopy} className={styles.copy_icon} />
            </td>
            <td className={styles.tableCell}>{meter?.FTMBalance}</td>
            <td className={styles.tableCell}>{meter.gas_fee}</td>
        </tr >
            <CreateUpdateMeterModal show={editMeter} handleClose={() => { setEditMeter(false); }} meter={meter} />
        </>
    )
}

export default MetersTableItem