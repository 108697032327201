import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config  from "../config";
import trgy from "../contracts/trgy.json";

const useTRGY = () => {
    const { provider } = useConnector();
    const [contract, setContract] = useState(null);
  
    useEffect(() => {
      if (provider) {
        const signer = provider.getSigner();
        setContract(new ethers.Contract(config.TRGY, trgy, signer));
      }
    }, [provider]);
     
    const getTRGYTotalSupply = async () => {
        let total  =  await contract?.totalSupply()
        return Number(total?._hex)
    }

    const getTRGBalance = async (address) => {
      let total  =  await contract?.balanceOf(address)
      const balanceEth = ethers.utils.formatEther(total);
      return +balanceEth;
  }
     
    return {getTRGYTotalSupply, getTRGBalance, TRG:contract}
  
}

export default useTRGY
