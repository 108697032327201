/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import NavBar from "../components/layout/NavBar";
import { Row } from "react-bootstrap";
import {
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonSpinner,
} from "@ionic/react";
import axios from "axios";
import { backend_url } from "../utils/be";
import { toast } from "react-toastify";
import useOrderBook from "../hooks/useOrderBook";
import ProsumerDetailSection from "../components/Prosumer/ProsumerDetailSection";
import { useAdminAPI } from "../api/admin.api";

export interface TradeSettings {
    id: string;
    fee: string;
    rate: number;
}

const TradePage = () => {
    const { updateFee, fee, spread, updateSpread } = useOrderBook();
    const [loading, setLoading] = useState(false);
    const [feeLoading, setFeeLoading] = useState(false);
    const [updatedFee, setUpdatedFee] = useState<number>(0);
    const [updatedSpread, setUpdatedSpread] = useState<number>(0);
    const { adminIsPermitted } = useAdminAPI()
    const userPermitted = adminIsPermitted("tradeSettings", "EDIT")

    async function setFee() {
        if (!userPermitted) return;
        setFeeLoading(true);
        let result = await updateFee(updatedFee);
        if (result) {
            toast.success("Fee updated successfully");
        } else {
            toast.error("Error updating fee");
        }
        setFeeLoading(false);
    }

    async function setSpread() {
        if (!userPermitted) return;
        setLoading(true);
        let result = await updateSpread(updatedSpread);
        if (result) {
            toast.success("Spread updated successfully");
        } else {
            toast.error("Error updating fee");
        }
        setLoading(false);
    }

    useEffect(() => {
        setUpdatedFee(fee);
        setUpdatedSpread(spread);
    }, [fee, spread]);

    return (
        <>
            <NavBar
                title={
                    <div className="">
                        <h1 className="text-2xl font-bold">Trade Page</h1>
                    </div>
                }
            />
            <Row>
                <div className="h-10"></div>
                <section className="grid-cols-2 md:grid">
                    <div className="shadow rounded p-3 space-y-3">
                        <h2 className="font-bold"> Settings</h2>
                        <div className="flex justify-between items-center">
                            <div className="flex-1 mr-6">
                                <IonItem>
                                    <IonLabel position="floating">Trade Fee</IonLabel>
                                    <IonInput
                                        disabled={!userPermitted}
                                        onIonInput={(e: any) => setUpdatedFee(e.target.value)}
                                        value={updatedFee}
                                        color={"dark"}
                                        type="number"
                                    />
                                </IonItem>{" "}
                            </div>
                            <IonButton

                                onClick={setFee}
                                disabled={feeLoading || !userPermitted}
                                color={"success"}
                                className="mt-3"
                                expand="block"
                            >
                                {feeLoading ? <IonSpinner /> : "Save"}
                            </IonButton>

                        </div>

                        <div className="flex justify-between items-center">
                            <div className="flex-1 mr-6">
                                <IonItem disabled={!userPermitted}>
                                    <IonLabel position="floating">Spread</IonLabel>
                                    <IonInput value={updatedSpread} onIonInput={(e: any) => setUpdatedSpread(e.target.value)} color={"dark"} type="number" />
                                </IonItem>
                            </div>
                            <IonButton
                                onClick={setSpread}
                                disabled={loading || !userPermitted}
                                color={"success"}
                                className="mt-3"
                                expand="block"
                            >
                                {loading ? <IonSpinner /> : "Save"}
                            </IonButton>
                        </div>
                    </div>
                </section>
            </Row>
        </>
    );
};

export default TradePage;
