import React, { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { DRRReward } from "../../types/Rewards";
import axios from "axios";
import { der_baseurl } from "../../pages/DERRewards";
import { useIonToast } from "@ionic/react";

const DERRewardsTable = ({ data, rowsPerPage, onChange }: { data: DRRReward[], rowsPerPage: number, onChange: () => void }) => {
    const [row_num, setrow_num] = useState(10);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, row_num);
    useIonToast()

    function deleteReward(id: string) {
        const headers: any = {
            'ngrok-skip-browser-warning': true,
            'Content-Type': 'multipart/form-data'
        }


        axios.delete(`${der_baseurl}/delete_reward/${id}`, { headers }).then((res) => {
            // console.log(res.data)
            onChange()
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        setPage(page + 1);
    }, [row_num]);
    return (
        <Row style={{ width: "100%", overflow: "scroll" }}>
            <div className="rshow text-right pr-3 mb-5">
                Show{" "}
                <Form.Select
                    aria-label="Select number of records"
                    style={{
                        width: "73px",
                        height: "35px",
                        display: "inline-block",
                    }}
                    className="ml-2 mr-2"
                    //   iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
                    value={row_num}
                    onChange={(e) => setrow_num(+e.target.value!)}
                >
                    <option value={10}>10</option>
                    <option value={2}>25</option>
                    <option value={50}>50</option>
                </Form.Select>
                Records
            </div>
            <table className={styles.table} style={{ width: "100%" }}>
                <thead className={styles.tableRowHeader} >
                    {/* <tr> */}
                    <th className={styles.tableHeader}>Manage</th>
                    <th className={styles.tableHeader}>Title</th>
                    <th className={styles.tableHeader}>Image</th>
                    <th className={styles.tableHeader}>Description</th>
                    <th className={styles.tableHeader}>Price</th>
                    <th className={styles.tableHeader}>Status</th>

                    {/* </tr> */}
                </thead>
                <tbody style={{ width: "100%" }}>
                    {(slice as DRRReward[]).map((el: any) => (
                        <tr
                            style={{ width: "100%" }}
                            className={styles.tableRowItems}
                            key={el.id}
                        >
                            <td className={styles.tableCell}>
                                <Dropdown className="drop">
                                    <Dropdown.Toggle
                                        style={{
                                            cursor: "pointer",
                                            background: "transparent",
                                            color: "black",
                                            border: "0px",
                                            outline: "none",
                                        }}
                                        className={styles.dtoggle}
                                        name="manage button"
                                    >
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item>View</Dropdown.Item>
                                        <Dropdown.Item onClick={() => deleteReward(el.id)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            <td className={styles.tableCell}>{el.title}</td>
                            <td className={styles.tableCell}>
                                <img src={el.image} alt="image url for reward" className="h-14 rounded-md" />
                            </td>
                            <td className={styles.tableCell} style={{ position: "relative" }}>
                                {el.description}
                                <FontAwesomeIcon icon={faCopy} className={styles.copy_icon} />
                            </td>
                            <td className={styles.tableCell}>{el.price}</td>
                            <td className={styles.tableCell}>{el.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </Row >
    );
};

export default DERRewardsTable;
