import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AccountDataPage from '../../pages/AccountDataPage'
import AdminRoles from '../../pages/AdminRolesPage'
import AdminsPage from '../../pages/AdminsPage'
import BalancesPage from '../../pages/BalancesPage'
import BenLEMPrice from '../../pages/BenLEMPrice'
import BlacklistPage from '../../pages/BlacklistPage'
import BrisLEMPrice from '../../pages/BrisLEMPrice'
import DERClaims from '../../pages/DERClaims'
import DERRewards from '../../pages/DERRewards'
import ErrorPage from '../../pages/ErrorPage'
import LEMPricePage from '../../pages/LEMPricePage'
import MetersPage from '../../pages/MetersPage'
import PausePage from '../../pages/PausePage'
import ProsumerPage from '../../pages/ProsumerPage'
import ReadingsPage from '../../pages/ReadingsPage'
import RewardsProsumerPage from '../../pages/RewardsProsumerPage'
import Test from '../../pages/Test'
import TokenomicsPage from '../../pages/TokenomicsPage'
import TradePage from '../../pages/TradePage'
import TradeProsumerPage from '../../pages/TradeProsumerPage'
import SideBar from '../layout/SideBar'
import { useAdminAPI } from '../../api/admin.api'
import LogsPage from '../../pages/LogsPage'

const AdminRoutes = () => {

    const { getAdminRoles } = useAdminAPI()

    useEffect(() => {
        getAdminRoles()
    }, [])

    return (
        <div className="overflow-scroll h-screen">
            <SideBar >
            </SideBar>
            {/* <NavBar /> */}
            <Routes>
                <Route path="/" element={<ProsumerPage />} />
                <Route path="/tokenomics" element={<TokenomicsPage />} />
                <Route path="/admins" element={<AdminsPage />} />
                <Route path="/admins-roles" element={<AdminRoles />} />
                <Route path="/readings" element={<ReadingsPage />} />
                <Route path="/account-data" element={<AccountDataPage />} />
                <Route path="/LEM-price" element={<LEMPricePage />} />
                <Route path="/LEM-price-Benetutti" element={<BenLEMPrice />} />
                <Route path="/LEM-price-Bristol" element={<BrisLEMPrice />} />
                <Route path="/DER-rewards" element={<DERRewards />} />
                <Route path="/we-test" element={<Test />} />
                <Route path="/DER-claims" element={<DERClaims />} />
                <Route path="/meters" element={<MetersPage />} />
                <Route path="/blacklist" element={<BlacklistPage />} />
                <Route path="/pause-contracts" element={<PausePage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/balances" element={<BalancesPage />} />
                <Route path="/rewards" element={<RewardsProsumerPage />} />
                <Route path="/trades" element={<TradeProsumerPage />} />
                <Route path="/admin-trades" element={<TradePage />} />
                <Route path="/logs" element={<LogsPage />} />
            </Routes>
            <ToastContainer />
        </div>
    )
}

export default AdminRoutes