import React, { useState, useEffect } from "react";
import { Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import EditAdminModal from "../Modals/EditAdminModal";
import { useSelector } from "react-redux";
import { selectAdminRoles, selectAdmins } from "../../redux/reducers/userReducer";
import { useAdminAPI } from "../../api/admin.api";

const AdminsTable = ({ data, rowsPerPage }) => {
  const [row_num, setrow_num] = useState(10);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, row_num);
  const [currentAdmin, setCurrentAdmin] = useState()
  const [editAdmin, setEditAdmin] = useState(false)
  const adminRoles = useSelector(selectAdminRoles)
  const { getAllAdmins } = useAdminAPI()
  const admins = useSelector(selectAdmins)


  useEffect(() => {
    setPage(page + 1);
  }, [row_num]);
  useEffect(() => {
    getAllAdmins()
  }, []);
  return (
    <Row style={{ width: "100%" }}>
      <div className="rshow text-right pr-3">
        Show{" "}
        <Form.Select
          aria-label="Select number of records"
          style={{
            width: "73px",
            height: "35px",
            display: "inline-block",
          }}
          className="ml-2 mr-2"
          iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
          value={row_num}
          onChange={(e) => setrow_num(e.target.value)}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </Form.Select>
        Records
      </div>
      <table className={styles.table} style={{ width: "100%" }}>
        <thead className={styles.tableRowHeader}>
          {/* <tr> */}
          <th className={styles.tableHeader}>Manage</th>
          <th className={styles.tableHeader}>Name</th>

          <th className={styles.tableHeader}>Email address</th>
          <th className={styles.tableHeader}>Role</th>

          {/* </tr> */}
        </thead>
        <tbody style={{ width: "100%" }}>
          {admins?.map((el) => {
            const roleName = adminRoles.find((role => role.id === el.role))?.title
            return (
              <tr
                style={{ width: "100%" }}
                className={styles.tableRowItems}
                key={el.id}
              >
                <td className={styles.tableCell}>
                  <Dropdown className="drop" >
                    <Dropdown.Toggle
                      style={{
                        cursor: "pointer",
                        background: "transparent",
                        color: "black",
                        border: "0px",
                        outline: "none",
                      }}
                      className={styles.dtoggle}
                      name="manage button"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu onClick={() => { setEditAdmin(true); setCurrentAdmin(el) }}>
                      <Dropdown.Item>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td className={styles.tableCell}>{el.name}</td>

                <td className={styles.tableCell}>{el.email}</td>
                <td className={styles.tableCell}>{roleName}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
      <EditAdminModal isOpen={editAdmin} adminData={currentAdmin} onDidDismiss={() => { setEditAdmin(false); setCurrentAdmin(undefined) }} />
    </Row >
  );
};

export default AdminsTable;
