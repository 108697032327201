import React, { useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    IonSpinner,
} from '@ionic/react';
import { IoClose } from 'react-icons/io5';
import { IAdminRoleAPI, IAdmin } from '../../types/admin';
import { adminRolesData, selectedRolesToAPIData } from '../../utils/admin';
import { useAdminAPI } from '../../api/admin.api';
import { useSelector } from 'react-redux';
import { selectAdminRoles } from '../../redux/reducers/userReducer';



interface IEditAdminModal {
    isOpen: boolean,
    onDidDismiss: () => void,
    adminData?: IAdmin
}



const EditAdminModal: React.FC<IEditAdminModal> = ({ isOpen, onDidDismiss, adminData }) => {
    const [name, setName] = useState(adminData?.name);
    const [email, setEmail] = useState(adminData?.email);
    const [role, setRole] = useState(adminData?.role)
    const { editAdminRoleById, getAllAdmins, editAdminData } = useAdminAPI()
    const adminRoles = useSelector(selectAdminRoles);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");


    function updateAdminData() {
        setName(adminData?.name);
        setEmail(adminData?.email);
        setRole(adminData?.role)
        setIsLoading(false)
    }


    async function submit(e: any) {
        e.preventDefault()

        if (adminData?.name !== name || adminData?.email !== email || adminData?.role !== role) {
            const result = await editAdminData({ name: name!, email: email!, role } as IAdmin, adminData?.id!)
            if (result) {
                getAllAdmins()
                onDidDismiss()
                setIsLoading(false)
                return
            }
        }

        if (adminData && role) {
            const result = await editAdminRoleById(role, adminData?.id)
            if (result) {
                getAllAdmins()
                onDidDismiss()
                setIsLoading(false)
                return;
            }
        }
        else {
            setError("Please select a role")
        }
        setIsLoading(false)

    }

    return (
        <IonModal onDidPresent={() => {
            updateAdminData()
        }} isOpen={isOpen} onDidDismiss={onDidDismiss}>
            <IonHeader mode="md" className="ion-no-shadow px-3">
                <IonToolbar>
                    <IonTitle>{adminData ? "Edit Admin" : "Create Admin"}</IonTitle>
                    <IonButtons slot="end" className="">
                        <IonButton onClick={onDidDismiss}>
                            <IoClose className="text-2xl" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="p-3">
                    <form onSubmit={submit}>
                        <IonItem lines="full">
                            <IonInput
                                value={name}
                                onIonInput={(e) => setName(e.detail.value!)}
                                label="Name"
                                labelPlacement="floating"
                                required
                            />
                        </IonItem>
                        <IonItem lines="full">
                            <IonInput
                                value={email}
                                onIonInput={(e) => setEmail(e.detail.value!)}
                                label="Email"
                                labelPlacement="floating"
                                required
                            />
                        </IonItem>
                        <IonItem>
                            <IonSelect
                                label="Role"
                                labelPlacement="floating"
                                value={role}
                                onIonChange={(e) => setRole(e.detail.value)}
                            >
                                {adminRoles.map((role, index) => (
                                    <IonSelectOption value={role.id} key={role.id}>
                                        {role.title}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>

                        <IonToolbar mode="ios" color="none" className="mt-6">
                            <IonButton type="submit" expand="block">
                                {isLoading ? <IonSpinner name="dots" /> : adminData ? "Edit" : "Add"}
                            </IonButton>
                        </IonToolbar>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </form>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default EditAdminModal;