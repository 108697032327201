import React, { useState } from 'react'
import { Dropdown, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { IClaim, RewardHistory } from "../../types/Rewards";
import { IonToggle, useIonToast } from "@ionic/react";
import axios from "axios";
import { der_baseurl } from "../../pages/DERRewards";
import { toast } from "react-toastify";



const ClaimRow = ({ el }: { el: IClaim }) => {

    const [toggleValue, setToggleValue] = useState(el.status.toLowerCase() == "processed" ? true : false)
    const [loading, setLoading] = useState(false)
    const [toastAlert] = useIonToast()
    const [status, setStatus] = useState(el.status)


    async function updateClaim(status: "open" | "processed") {

        const headers: any = {
            'ngrok-skip-browser-warning': true,
        };

        setLoading(true)

        await axios.get(`${der_baseurl}/issue_claim/${el.id}/${status}`, { headers }).then((res) => {
            if (res.data.success) {
                // console.log(res.data.data);
                toastAlert({ message: "Successfull", position: "top", color: "success", duration: 3000, mode: "ios" })
                setStatus(status)
            }
        }).catch((err) => {
            toast.error(err.message);
            setToggleValue(!toggleValue)
        })
        setLoading(false)
    }

    function convertTimestamp() {
        const [date, time] = el.createdAt.split("T")
        const finalTime = time.split(":")[0] + ":" + time.split(":")[1]
        return date.split("-").reverse().join("-") + " " + finalTime
    }

    return (
        <tr
            style={{ width: "100%" }}
            className={styles.tableRowItems}
            key={el.id}
        >
            <td className={styles.tableCell}>
                {el.username}
            </td>
            <td className={styles.tableCell}>{el.title}</td>
            <td className={styles.tableCell}>{convertTimestamp()}</td>
            <td className={styles.tableCell}>{el.email}</td>
            <td className={styles.tableCell} style={{ position: "relative" }}>
                <IonToggle disabled={loading} checked={toggleValue} onIonChange={(e) => { updateClaim(!e.target.checked ? "open" : "processed"); setToggleValue(e.detail.checked) }} color={'success'} />
            </td>
            <td className={styles.tableCell}>{status}</td>
        </tr>
    )
}

export default ClaimRow