export default [
  {
    id: 1,
    name: "One",
    fname: "1",
    email: "one@mail.com",
  },
  {
    id: 2,
    name: "Two",
    fname: "2",
    email: "two@mail.com",
  },
  {
    id: 3,
    name: "Three",
    fname: "3",
    email: "three@mail.com",
  },
  {
    id: 4,
    name: "Four",
    fname: "4",
    email: "four@mail.com",
  },
  {
    id: 5,
    name: "Five",
    fname: "5",
    email: "five@mail.com",
  },
  {
    id: 6,
    name: "Six",
    fname: "6",
    email: "six@mail.com",
  },
];
