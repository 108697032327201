/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import order_book from "../contracts/orderbook.json";
import { toast } from "react-toastify";

const useOrderBook = () => {
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const [fee, setFee] = useState(0);
  const [spread, setSpread] = useState(0);
  const [book_paused, setBookPaused] = useState(false);
  const location = window.location;

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.order_book, order_book, signer));
    }
  }, [provider, location.pathname]);

  useEffect(() => {
    if(contract){
        contract.fee().then(fee => {
          setFee(+ethers.utils.formatUnits(fee, 18));
        })
        contract?.spread().then(_spread => {
          setSpread(+ethers.utils.formatUnits(_spread, 18));
        })
    }
  },[contract])

  const updateFee = async (fee) => {
    try {
        let txn = await contract.setFee(ethers.utils.parseUnits(String(fee), "ether"));
        await txn.wait();
        return true;
    } catch (error) {
        console.error(error)
        return false;
    }
  };

  const updateSpread = async (_spread) => {
    try {
        let txn = await contract.setSpread(ethers.utils.parseUnits(String(_spread), "ether"));
        await txn.wait();
        return true;
    } catch (error) {
        console.error(error)
        return false;
    }
  };

  const pauseBook = async () => {
    try {
      await contract?.pauseBook();
      toast.success("Order book  Paused !!");
    } catch (error) {
      toast.error("Operation error !!");
    }
  };

  const unPauseBook = async () => {
    try {
      await contract?.unPauseBook();
      toast.success("Order book Unpaused !!");
    } catch (error) {
      toast.error("Operation error !!");
    }
  };

  return {
    fee,
    spread,
    book_paused,
    updateFee, 
    updateSpread,
    pauseBook,
    unPauseBook,
    setBookPaused
  };
};

export default useOrderBook;
