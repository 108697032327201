import { IonSkeletonText } from '@ionic/react'
import React from 'react'

const TableSkeleton: React.FC<{ number: number }> = ({ number }) => {
    //array of length number
    const arr = Array.from(Array(number * 5).keys())
    return (
        <div className='grid grid-cols-5 gap-3 my-6'>
            {
                arr.map((el, index) => (
                    <IonSkeletonText key={index} animated className='h-10 rounded-sm' />
                ))
            }
        </div>
    )
}

export default TableSkeleton