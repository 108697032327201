import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios, { AxiosHeaders } from "axios";
import { toast } from "react-toastify";
import {
    Form,
    Button,
    FormCheck,
    Row,
    Col,
    Card,
} from "react-bootstrap";
import { backend_url } from "../utils/be";
import { IonCheckbox, IonSpinner, useIonToast } from "@ionic/react";
import footerImage from "../asserts/images/footer_flag.svg";
import "./../styles/login.css";
import { IoArrowBack } from "react-icons/io5";
import { setUser } from "../redux/reducers/userReducer";


export default function OTPVerification() {
    let navigate = useNavigate();
    const location = useLocation()
    const [errorMessage, setErrorMessage] = useState("")
    const [code, setcode] = useState("");
    const [loading, setLoading] = useState(false)
    const email = (location.state as any)?.email
    const [toast] = useIonToast()
    const dispatch = useDispatch()



    const verifyCode = async (e: any) => {
        e.preventDefault();
        if (code.length < 6) {
            return setErrorMessage("Please enter a valid code")
        }

        setErrorMessage("")
        setLoading(true)

        await axios.post(`${backend_url}/admin/verify_otp`, {
            email,
            otpCode: code
        }).then(async (response) => {

            if (response.data.success) {


                const res = await axios.get(
                    `${backend_url}/admin/admin/${response.data.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${response.data.token}`
                        }
                    }
                );


                if (res.data.success) {
                    dispatch(setUser({
                        ...res.data.data,
                        token: response.data.token
                    }));
                    console.log({
                        ...res.data.data,
                        token: response.data.token
                    })
                    window.sessionStorage.setItem("user_logged_in", "true");
                    window.location.href = "/";
                } else {
                    toast({
                        message: res.data.message,
                        buttons: ["ok"],
                        color: "danger",
                    });
                }
            } else {
                setErrorMessage(response?.data.message)
            }


        }).catch(err => {
            console.log(err)
            setErrorMessage("Something went wrong. please try again!")
        })


        setLoading(false)


    };


    return (
        <React.Fragment>
            <Row className="col-container">
                <Col className="left-col " sm="12" md="6">

                    <div>
                        <Link to={'/signin'} replace className="p-3 text-start w-full text-primaryGreen">
                            <button className="flex items-center gap-x-3" name="lOGIN">
                                <IoArrowBack className="text-2xl" /> <span>Login</span>
                            </button>
                        </Link>
                        <h1 className="">2FA Authentication</h1>
                        <p className="text-gray-500 font-light">Provide the code from your Google Authenticator App</p>
                        <form onSubmit={(e) => verifyCode(e)} className="text-left ">
                            <div className="text-black font-light mb-3">
                                <label className="text-[14px]">Insert Code</label>
                                <input
                                    required
                                    value={code}
                                    name="code"
                                    onChange={(e) => setcode(e.target.value)}
                                    style={{ letterSpacing: "5px" }}
                                    placeholder="_ _ _ _ _ _"
                                    className={`${styles["login-input"]} text-center`}
                                    type="number" />
                            </div>
                            <div className="mt-1 text-red-500">
                                {errorMessage}
                            </div>
                            <div className="mt-10 ">
                                <button type="submit" className="p-3 w-full bg-[#7EDD62] rounded-xl text-white md:text-sm" name="sign in">
                                    {!loading ? "Sign In" : <IonSpinner color={"dark"} />}
                                </button>
                            </div>

                        </form>

                    </div>
                </Col>
                <Col className="right-col relative">
                    <Card
                        style={{ width: "18rem", border: 0, boxShadow: "none" }}
                        className="right-card"
                    >
                        <Card.Img
                            variant="top"
                            src={require("./../asserts/images/logo.svg").default}
                        />
                        <Card.Text className="right-text">
                            Transactive Energy Module
                        </Card.Text>
                    </Card>
                    <div className="absolute bottom-0 text-left w-full px-4 lg:px-10 py-4 text-[#8F9BBA] items-center text-xs flex">
                        <img src={footerImage} className="w-14 rounded-sm" alt="footer" />
                        <div className="mx-2">
                            Twinergy has received funding from the European Union's Horizon 2020 Research and Innovation Programme under Grant Agreement No. 957736
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

const styles = {
    "login-input": "inline-block w-full placeholder:font-light placeholder:text-gray-600 border-gray-600 border-[0.5px] rounded-xl p-2 pl-3 outline-yellow-500"
}
