import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./../styles/prosumer.css";
import blacklistData from "../data/blacklist";
import BlacklistTable from "../components/Table/BlacklistTable";
import NavBar from "../components/layout/NavBar";
import useTRGYVault from "../hooks/useTRGYVault";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/reducers/userReducer";

export default function BlacklistPage() {
  const refreshList = React.useRef(null);
  const [Blacklisted] = useState([...blacklistData]);
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState();
  const { blackListAddress } = useTRGYVault();
  const user = useSelector(selectUser)

  const handleMouseEnter = e => {
    e.target.style.color = "#06152b";
  };
  const handleMouseLeave = e => {
    e.target.style.color = "white";
  };

  const handleBlacklist = async () => {
    setLoading(true);
    let result = await blackListAddress(address);
    if (result) await blacklist_address();
    if (result) {
      toast.success("Operation Successful");
    } else {
      toast.error("Operation Failed");
    }
    setLoading(false);
  };

  const blacklist_address = async () => {
    const admin_name = user.email;
    axios
      .post("/admin/blacklist_add", { admin_name, address })
      .then(res => {
        if (res.data.success) {
          toast.success(res.data.message);
          refreshList.current();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  return (
    <React.Fragment>
      <NavBar t={1} l1="/blacklist" l2="/blacklist" l3="/blacklist" />
      <Container style={{ padding: 15 }}>
        <Row className="fsearch pl-3">
          <Form.Group
            className="fgroup"
            controlId="exampleForm.ControlInput1"
            style={{ width: "18rem" }}
          >
            <Form.Label className="flabel">Wallet address</Form.Label>
            <div className="md:flex grid gap-y-3">
              <input
                value={address}
                name="address"
                className="fcontrol mr-2"
                onChange={e => setAddress(e.target.value)}
                type="text"
                placeholder="Enter Address"
                style={{ paddingLeft: "10px", width: "100%" }}
              />
              <Button
                size="sm"
                className=""
                style={{
                  backgroundColor: "#7edd62",
                  borderRadius: "10px",
                  padding: "10px, 8px, 10px, 8px,",
                  border: "none",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleBlacklist}
              >
                {loading ? "Loading..." : "Blacklist"}
              </Button>
            </div>
          </Form.Group>


        </Row>
        <Row className="tcontainer">
          <Col xs={12} className="table_col">
            <div className="tcont1">
              <div className="ttitle">Blacklisted wallet addresses</div>
              <div className="tcard">
                <BlacklistTable
                  data={Blacklisted}
                  rowsPerPage={10}
                  refreshList={refreshList}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
