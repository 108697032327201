import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config  from "../config";
import watt from "../contracts/watt.json";

const useWATT = () => {
    const { provider } = useConnector();
    const [contract, setContract] = useState(null);
  
    useEffect(() => {
      if (provider) {
        const signer = provider.getSigner();
        setContract(new ethers.Contract(config.WATT, watt, signer));
      }
    }, [provider]);
     
    const getWATTTotalSupply = async () => {
        let total  =  await contract?.totalSupply()
        return Number(total?._hex)
    }
    

    return {getWATTTotalSupply}
}

export default useWATT
