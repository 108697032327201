/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import axios from "axios";
import { copyAddress } from "../../utils/clipboard";
import TableSkeleton from "../layout/tableSkeleton";
import { IonSkeletonText, IonToggle } from "@ionic/react";
import { useProsumerAPI } from "../../api/prosumer.api";

const ProsumerTable = ({ rowsPerPage }) => {
  const [row_num, setrow_num] = useState(10);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const { slice, range } = useTable(users, page, row_num);
  const [loading, setLoading] = useState(false)
  const { getAllProsumers } = useProsumerAPI()

  useEffect(() => {
    setPage(page + 1);
  }, [row_num]);


  useEffect(() => {
    getProsumers();
  }, []);

  async function getProsumers() {
    setLoading(true)
    const prosumers = await getAllProsumers();
    setTimeout(() => {
      setUsers(prosumers ?? []);
      setLoading(false)
    }, 2000);
  }

  return (
    <div>
      <Row style={{ display: "flex", width: "100%" }}>
        <div className="rshow text-right pr-3 mb-4 ">
          Show{" "}
          <Form.Select
            aria-label="Select number of records"
            style={{
              width: "73px",
              height: "35px",
              display: "inline-block",
            }}
            className="ml-2 mr-2"
            iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
            value={row_num}
            onChange={(e) => setrow_num(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={2}>25</option>
            <option value={50}>50</option>
          </Form.Select>
          Records
        </div>
        <table className={styles.table}  >
          <thead className={styles.tableRowHeader}  >
            {/* <tr> */}
            <th className={styles.tableHeader}>Manage</th>
            <th className={styles.tableHeader}>Name</th>
            <th className={styles.tableHeader}>Family name</th>
            {/* <th className={styles.tableHeader}>Username</th> */}
            <th className={styles.tableHeader}>Email address</th>
            <th className={styles.tableHeader}>Country</th>
            <th className={styles.tableHeader}>Wallet address</th>
            <th className={styles.tableHeader}>2FA Enabled</th>
            {/* </tr> */}
          </thead>

          <tbody style={{ width: "100%" }}>

            {users?.map((el) => (
              <tr
                style={{ width: "100%", }}
                className={styles.tableRowItems}
                key={el.id}
              >
                <td className={styles.tableCell}>
                  <Dropdown className="drop">
                    <Dropdown.Toggle
                      style={{
                        cursor: "pointer",
                        background: "transparent",
                        color: "black",
                        border: "0px",
                        outline: "none",
                      }}
                      className={styles.dtoggle}
                      name="manage button"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link
                          to="/account-data"
                          state={el}
                          style={{ textDecoration: "none" }}
                        >
                          View
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td className={styles.tableCell}>
                  <div className="py-3">
                    {el.name}
                  </div>
                </td>
                <td className={styles.tableCell}>{el.family_name}</td>

                <td className={styles.tableCell}>{el.email}</td>
                <td className={styles.tableCell}>{el.country}</td>
                <td
                  className={styles.tableCell}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  {`${el.wallet?.slice(0, 5)}...${el.wallet?.slice(38, 42)}`}
                  <FontAwesomeIcon
                    icon={faCopy}
                    className={styles.copy_icon}
                    onClick={() => copyAddress(el.wallet)}
                  />
                </td>
                <td className={styles.tableCell}>
                  <IonToggle disabled checked={!!el.fa_key} />
                </td>

              </tr>
            ))}
          </tbody>
        </table>
        {loading && <TableSkeleton number={5} />}

        <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
      </Row>
    </div >
  );
};

export default ProsumerTable;
