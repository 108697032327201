import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export interface NavLinkProps {
    l1: string,
    l2: string,
}


const AdminNavLinks: React.FC<{ props: NavLinkProps }> = ({ props, }) => {
    const location = useLocation()
    const { pathname } = location
    return (
        <div className=' flex  gap-x-2 lg:gap-x-3'>
            <NavLink to={"/admins"}>
                <span className={`text-sm lg:text-md ${pathname == "/admins" ? "font-bold underline underline-offset-4" : "font-normal"}  text-black mr-3 flex gap-x-1 items-center`}> Admins </span>
            </NavLink>
            <NavLink to={props.l1}>
                <span className={`text-sm lg:text-md ${pathname == "/admins-roles" ? "font-bold underline underline-offset-4" : "font-normal"}  text-black mr-3 flex gap-x-1 items-center`}> Roles </span>
            </NavLink>


        </div>
    )
}

export default AdminNavLinks