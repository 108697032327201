import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import { setUserState } from "../redux/reducers/appStateReducer";
import { IonCheckbox, IonSpinner, useIonToast } from "@ionic/react";
import footerImage from "../asserts/images/footer_flag.svg";
import "./../styles/login.css";
import { selectUser, setUser } from "../redux/reducers/userReducer";
import { backend_url } from "../utils/be";



export default function LoginPage() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  let navigate = useNavigate();
  const [toast, setToast] = useIonToast()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)


  function validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const signin = async (e: any) => {
    e.preventDefault()
    //minimum of 12 characters
    if (pass?.length < 12) return toast({ message: "Password must be minimum of 12 characters", color: "danger", buttons: ["ok"], duration: 3000 });

    if (email === "") return toast({ message: "Email is required", color: "danger", buttons: ["ok"], duration: 3000 });

    if (validateEmail(email) === false) return toast({ message: "Invalid Email", color: "danger", buttons: ["ok"] });
    setLoading(true)
    try {
      const response = await axios.post(`${backend_url}/admin/login`, {
        email,
        password: pass,
      });

      if (response.data.fa_auth) {
        navigate("/verify-otp", { replace: true, state: { email } })
        return;
      }


      if (response.data.success) {
        if (response.data.token !== null) {



          // Add authorization header
          const headers = {
            Authorization: `Bearer ${response.data.token}`,
          };

          const res = await axios.get(
            `${backend_url}/admin/admin/${response.data.id}`,
            { headers }
          );


          if (res.data.success) {
            dispatch(setUser({
              ...res.data.data,
              token: response.data.token
            }));

            window.sessionStorage.setItem("user_logged_in", "true");
            window.location.href = "/"
            window.location.reload();
          } else {
            toast({
              message: res.data.message,
              buttons: ["ok"],
              color: "danger",
            });
          }

        } else {
          toast({
            message: response.data.message,
            buttons: ["ok"],
            color: "danger",
          });
        }
      } else {
        console.log(response.data.message);
        toast({
          message: response.data.message,
          buttons: ["ok"],
          color: "danger",
        });
      }

    } catch (err: any) {
      console.log(err)
      toast({
        message: err.response?.data?.message || err.message,
        buttons: ["ok"],
        color: "danger",
      });

    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <Row className="col-container">
        <Col className="left-col " sm="12" md="6">
          <div>
            <h1 className="text-3xl mb-2">Sign In</h1>
            <p className="text-gray-500 font-light text-sm mb-4">Enter your username and password to sign in!</p>
            <form onSubmit={signin} className="text-left ">
              <div className="text-black font-light mb-3">
                <label className="text-[14px] mb-1">Username/Email*</label>
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="mail@simmple.com" className={styles["login-input"]} type="text"
                  name="email"
                />
              </div>
              <div className="text-black font-light mb-3">
                <label className="text-[14px] mb-1">Password*</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={pass}
                    onChange={(e) => setpass(e.target.value)}
                    placeholder="Min. 12 characters" className={styles["login-input"]}
                    name="password"
                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="absolute right-3 text-gray-600 top-1/3">
                    {!showPassword ? <AiOutlineEye /> :
                      <AiOutlineEyeInvisible />}
                  </span>
                </div>

              </div>
              <div className="grid grid-cols-2 font-light text-sm ">
                <div className="items-center flex">
                  <IonCheckbox color={"warning"} style={{ "--border-color": "var(--ion-color-warning)" }} />
                  <span className="ml-1">Keep me logged in</span>
                </div>
                <div className="text-right mt-[3px] ">
                  <Link to="" className="text-yellow-500 hover:text-yellow-500">Forgot Password?</Link>
                </div>
              </div>
              <div className="mt-10 ">
                <button type="submit" className="p-3 w-full bg-[#7EDD62] rounded-xl text-white text-sm">
                  {!loading ? "Sign In" : <IonSpinner color={"dark"} />}
                </button>
              </div>
            </form>
          </div>
        </Col>
        <Col className="right-col relative">
          <Card
            style={{ width: "18rem", border: 0, boxShadow: "none" }}
            className="right-card"
          >
            <Card.Img
              variant="top"
              src={require("./../asserts/images/logo.svg").default}
            />
            <Card.Text className="right-text">
              Transactive Energy Module <br />Back-end
            </Card.Text>
          </Card>
          <div className="md:absolute bottom-0 text-left w-full px-4 lg:px-10 py-4 text-[#8F9BBA] items-center text-xs flex">
            <img src={footerImage} className="w-14 rounded-sm" alt="footer" />
            <div className="mx-2">
              Twinergy has received funding from the European Union's Horizon 2020 Research and Innovation Programme under Grant Agreement No. 957736
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const styles = {
  "login-input": "inline-block w-full placeholder:font-light placeholder:text-gray-600 border-gray-600 border-[0.5px] rounded-xl p-2 pl-3 outline-yellow-500"
}
