import { IonItem, IonLabel, IonButton } from '@ionic/react';
import React, { useState } from 'react'
import { IoTrashOutline } from 'react-icons/io5';
import { IAdminRole } from '../../types/admin';
import { useAdminAPI } from '../../api/admin.api';

const AdminRoleItem: React.FC<{ role: IAdminRole, onClick: () => void, _onDelete: () => void }> = ({ onClick, role, _onDelete }) => {
    const { deleteAdminRole } = useAdminAPI()
    const [isLoading, setIsLoading] = useState(false)

    async function onDelete() {
        setIsLoading(true)
        await deleteAdminRole(role.id!)
        _onDelete()
        setIsLoading(false)
    }

    return (
        <IonItem disabled={isLoading} button mode="md" lines="none" className="mb-1" slot="header" color="light" >
            <div className="w-full" onClick={onClick} >
                <IonLabel>{role.title}</IonLabel>
            </div>

            <IonButton color='danger' onClick={() => { onDelete(); }} slot="end" fill="clear">
                {!role.isSuperAdmin && <IoTrashOutline className="text-xl" />}
            </IonButton>
        </IonItem>

    )
}

export default AdminRoleItem