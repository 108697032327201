import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import "./../styles/prosumer.css";
import ReadingsTable from "../components/Table/ReadingsTable";
import NavBar from "../components/layout/NavBar";
import axios from "axios";
import { IMeter, MeterReadings } from "../types/Prosumer";
import { backend_url } from "../utils/be";
import { useSelector } from "react-redux";
import { selectProsumer } from "../redux/reducers/userReducer";
import useMeters from "../hooks/useMeters";
import ProsumerDetailSection from "../components/Prosumer/ProsumerDetailSection";
import { useProsumerAPI } from "../api/prosumer.api";

export default function ReadingsPage() {
  //create and initialize an array of prosumers with a tuple of 6 elements
  const [readings, setReadings] = useState<MeterReadings[]>([]);
  const prosumer = useSelector(selectProsumer);
  const { getReadings, contract } = useMeters();
  const [loading, setLoading] = useState(false)
  const { getProsumerReadings } = useProsumerAPI()

  useEffect(() => {

    async function fetchReadings() {
      if (!prosumer) return;
      setLoading(true)
      const _meters = await getProsumerReadings(prosumer)
      setReadings(_meters);
      setLoading(false);
    }
    fetchReadings()

  }, [contract, prosumer]);


  return (
    <React.Fragment>
      <NavBar
        t={2}
        l1="/account-data"
        l2="/readings"
        l3="/meters"
        l4="/balances"
        l5="/rewards"
        l6="/trades"
      />
      <ProsumerDetailSection />
      <Container style={{ padding: 15 }}>
        <Row className="tcontainer">
          <Col xs={12} className="table_col">
            <div className="tcont1">
              <div className="ttitle">Readings</div>
              <div className="tcard">
                <ReadingsTable data={readings} rowsPerPage={4} loading={loading} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
