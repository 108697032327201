
// convert day hours from 0 to 23 to am and pm
export const convertTimeAMPM = (time: number) => {
    if (time < 12) {
        return `${time} am`;
    }
    if (time === 12) {
        return `${time} pm`;
    }
    return `${time - 12} pm`;
}

// convert day of the week from 0 to 6 to day of the week
