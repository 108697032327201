import React from "react";
import {
    Button,
    Col,
    Row,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import "../nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../nav.module.css";
import { NavLink } from "react-router-dom";



const ChangePasswordSegment: React.FC = () => {

    const tooltip = (
        <Tooltip id="tooltip">
            Passwords must contain a minimum of 12 characters
        </Tooltip>
    );

    const btnhandleMouseEnter = (e: any) => {
        e.target.style.color = "#1E2727";
    };
    const btnhandleMouseLeave = (e: any) => {
        e.target.style.color = "white";
    };


    return (
        <div>
            <Form>
                <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                    style={{ width: "100%" }}
                >
                    <Form.Label>Current Password</Form.Label>

                    <Form.Control
                        type="password"
                        placeholder="Password"
                        className={styles.finput}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                    style={{ width: "100%" }}
                >
                    <Form.Label>
                        New Password{" "}
                        <OverlayTrigger placement="bottom" overlay={tooltip}>
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className={styles.infoIcon}
                            />
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        className={styles.finput}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                    style={{ width: "100%" }}
                >
                    <Form.Label>
                        Confirm Password{" "}
                        <OverlayTrigger placement="bottom" overlay={tooltip}>
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className={styles.infoIcon}
                            />
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        className={styles.finput}
                    />
                </Form.Group>
            </Form>

            <div>
                <Button
                    className={styles.sbtn}
                    style={{
                        backgroundColor: "#7EDD62",
                        borderRadius: "10px",
                        border: "none",
                    }}
                    onMouseEnter={btnhandleMouseEnter}
                    onMouseLeave={btnhandleMouseLeave}
                    name="save"
                >
                    Save
                </Button>
            </div>

        </div>
    );
};

export default ChangePasswordSegment;