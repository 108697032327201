import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Dropdown,
} from "react-bootstrap";
import "./../styles/prosumer.css";

import prosumerData from "../data/prosumers";
import ProsumerTable from "../components/Table/Prosumer";
import NavBar from "../components/layout/NavBar";

export default function ProsumerPage() {
  //create and initialize an array of prosumers with a tuple of 6 elements

  return (
    <React.Fragment>
      <NavBar t={0} />
      <div className="p-10">
        <Row className="fsearch pl-3 items-center">
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Name</Form.Label>
            <Form.Control className="fcontrol" type="text" placeholder="" />
          </Form.Group>
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Family name</Form.Label>
            <Form.Control className="fcontrol" type="text" placeholder="" />
          </Form.Group>
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Username</Form.Label>
            <Form.Control className="fcontrol" type="text" placeholder="" />
          </Form.Group>
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Email address</Form.Label>
            <Form.Control className="fcontrol" type="email" placeholder="" />
          </Form.Group>
          <div className="fgroup">
            <button
              className="w-32 mt-3 text-white rounded-md bg-[#7EDD62] p-[10px] md:float-right "
            >
              Search
            </button>
          </div>
        </Row>
        <Row className="tcontainer ">
          <Col
            xs={12}
            className="table_col pr-0"
            style={{ borderRadius: "20px" }}
          >
            <div className="tcont1 pr-0" >
              <div className="ttitle mb-">Prosumers</div>
              <div className="tcard pr-0" >
                <ProsumerTable rowsPerPage={10} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
