import { AreaChart, XAxis, YAxis, Area, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const data = [
    { timestamp: new Date('2023-02-18T01:00:00'), value: 10 },
    { timestamp: new Date('2023-02-18T02:00:00'), value: 15 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    { timestamp: new Date('2023-02-18T03:00:00'), value: 8 },
    // Add more data entries for different days and hours
    // { timestamp: new Date('2023-02-19T01:00:00'), value: ... },
    // { timestamp: new Date('2023-02-19T02:00:00'), value: ... },
    // ...
];

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${day}/${month}/${year} - ${formattedHours}${period}`;
};

const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    const formattedDate = formatDate(payload.value);

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {formattedDate}
            </text>
        </g>
    );
};

const CustomizedXAxis = () => (
    <XAxis
        dataKey="timestamp"
        tick={<CustomizedXAxisTick />}
        interval={0}
        height={60}
        tickLine={false}
    />
);

const Test = () => (
    <div className='p-6'>

    </div>
);



export default Test;