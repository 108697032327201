import React from 'react'
import { useSelector } from 'react-redux';
import { Prosumer, selectProsumer } from '../../redux/reducers/userReducer';
import { Row } from 'react-bootstrap';

const ProsumerDetailSection = () => {
    const { wallet, name, id } = useSelector((selectProsumer)) as Prosumer;

    return (
        <Row className="bg-[#fcfcfc] py-4">

            <div className="flex items-center gap-3">
                <div>
                    <button className="text-2xl font-bold w-12 h-12 rounded-full bg-yellow-500" name='name'>
                        {name[0]}
                    </button>
                </div>
                <div>
                    <h2 className="text-lg font-medium">{name}</h2>
                    <p className="text-sm">{wallet?.substring(0, 10)}...{wallet?.substring(wallet.length - 10, wallet.length)}</p>
                    <p className="text-sm">{id}</p>
                </div>
            </div>
        </Row>
    )
}

export default ProsumerDetailSection