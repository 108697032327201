

export function meterNameToDescription(meterDwellingIdentifier: string) {
    let name = meterDwellingIdentifier.replace(" ", "").replace("meter", "").toLowerCase().trim();

    switch (name) {

        case "batterymain":
            return "Main electricity stored";
        case "main":
            return "Main electricity usage from the grid";
        case "delivery":
            return "Main electric delivery to the grid";
        case "pv":
            return "Measuring kWh generation";
        case "batterycharge":
            return "Stored pv generated energy";
        case "batterydischarge":
            return "Consumption of stored energy";
        case "batterystateofcharge":
            return "Current volume of stored kWh";
        case "batterytransactbuy":
            return "Purchase of energy from the LEM";
        case "batterytransactsell":
            return "Sale of stored energy to the LEM";
        case "batterytransact":
            return "Batery Transact Energy stored or sold to LEM";
        default:
            console.log(name);
            return "Unknown meter";
    }
}