import React, { useEffect } from "react";

import styles from "./TableFooter.module.css";
import { IoChevronBack, IoChevronForward } from "react-icons/io5"

const TableFooter = ({ range, setPage, page, slice }) => {
  // useEffect(() => {
  //   if (slice.length < 1 && page !== 1) { //if the current page is empty and not the first page
  //     setPage(page - 1);
  //   }
  // }, [slice, page, setPage]);

  const leftEdge = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const rightEdge = () => {
    if (page < range.length) {
      setPage(page + 1);
    }
  };
  return (
    <div className={styles.tableFooter}>
      <button
        className={`${styles.button} ${styles.inactiveButton}`}
        onClick={() => leftEdge()}
        style={{ borderRadius: "10px 0 0 10px" }}
        name="leftEdge"
      >
        <IoChevronBack />
      </button>
      {range.map((el, index) => (
        <button
          name="pageButton"
          key={index}
          className={`${styles.button} ${page === el ? styles.activeButton : styles.inactiveButton
            }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))}
      <button
        className={`${styles.button} ${styles.inactiveButton}`}
        onClick={() => rightEdge()}
        style={{ borderRadius: "0 10px 10px 0" }}
        name="rightEdge"
      >
        <IoChevronForward />
      </button>
    </div>
  );
};

export default TableFooter;
