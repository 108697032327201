import { configureStore } from "@reduxjs/toolkit";
import twinergyReducer from "./reducers/twinergyReducer";
import userReducer from "./reducers/userReducer";

export const store = configureStore({
  reducer: {
    twinergy: twinergyReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
