import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./../styles/prosumer.css";
import NavBar from "../components/layout/NavBar";
import LogsTable from "../components/Table/LogsTable";

export default function LogsPage() {



    return (
        <React.Fragment>
            <NavBar />
            <div className="p-5">

                <Row className="tcontainer">
                    <Col xs={12} className="table_col" style={{ borderRadius: "20px" }}>
                        <div className="tcont1">
                            <div className="ttitle">Logs</div>
                            <div className="tcard">
                                <LogsTable rowsPerPage={2} style={{ flex: 1 }} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}
