import { HashRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { backend_url } from "./utils/be";
import OTPVerification from "./pages/OTPVerification";
import { IonApp } from "@ionic/react";
import AdminRoutes from "./components/routes/AdminRoutes";

axios.defaults.baseURL = backend_url
axios.defaults.headers.common['ngrok-skip-browser-warning'] = true;

export default function App() {
  let user_logged_in = window.sessionStorage.getItem("user_logged_in");
  if (user_logged_in) {
    return (
      <IonApp>
        <Router>
          <AdminRoutes />
        </Router>
      </IonApp>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/get-started" element={<SignupPage />} />
          <Route path="/signin/:udata" element={<LoginPage />} />
          <Route path="/signin/" element={<LoginPage />} />
          <Route path="/verify-otp" element={<OTPVerification />} />
        </Routes>
      </Router>
    );
  }
}

