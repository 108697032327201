import React from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import "./../styles/walletpage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "../components/layout/NavBar";
import {
  faCoffee,
  faShareAltSquare,
  faShareFromSquare,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import useData from "../hooks/useData";
import { useSelector } from "react-redux";

export default function TokenomicsPage() {
  const {
    drrTotalSupply,
    trgyTotalSupply,
    wattTotalSupply,
    drrTradingVol,
    trgyTradingVol,
    wattTradingVol,
  } = useSelector((state) => state.twinergy);
  const data = useData();

  return (
    <React.Fragment>
      <NavBar />
      <Row className="">
        <Col className="">
          <div className="grid lg:grid-cols-2 gap-3 p-2 gap-y-4 md:p-5">
            <div
              className="p-4 shadow-md rounded-xl"
            >
              <Card.Body>
                <Card.Title className="card-title">WATT token </Card.Title>

                <Form.Group>
                  <Form.Label htmlFor="wattTradingVol" className="htitle">
                    24 hour trading volme
                  </Form.Label>
                  <input
                    type="text"
                    id="wattTradingVol"
                    className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                    value={wattTradingVol}
                    name="wattTradingVol"
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Form.Label htmlFor="circulating" className="htitle">Circulating supply</Form.Label>
                  <input
                    type="text"
                    id="circulating"
                    className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                    value={wattTotalSupply}
                    name="wattTotalSupply"
                  />
                </Form.Group>
              </Card.Body>
            </div>
            <div
              className="p-4 shadow-md rounded-xl"
            >
              <Card.Body>
                <Card.Title className="card-title">TRGY token </Card.Title>

                <Form.Group>
                  <Form.Label htmlFor="trading-vol" className="htitle">
                    24 hour trading volme
                  </Form.Label>
                  <input
                    type="text"
                    id="trading-vol"
                    className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                    value={trgyTradingVol}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Form.Label htmlFor="total-supply" className="htitle">Circulating supply</Form.Label>
                  <input
                    type="text"
                    id="total-supply"
                    className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                    value={trgyTotalSupply}
                  />
                </Form.Group>
              </Card.Body>
            </div>
            <div
              className="p-4 shadow-md rounded-xl"

            >
              <Card.Body>
                <Card.Title className="card-title">DRR token </Card.Title>

                <Form.Group>
                  <Form.Label className="htitle" htmlFor="drrTradingVol">
                    24 hour trading volme
                  </Form.Label>
                  <input
                    type="text"
                    id="drrTradingVol"
                    className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                    value={drrTotalSupply}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Form.Label className="htitle" htmlFor="circulating-supply">
                    Circulating supply</Form.Label>
                  <input
                    type="text"
                    id="circulating-supply"
                    className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                    value={drrTotalSupply}
                  />
                </Form.Group>
              </Card.Body>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
