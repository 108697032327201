
import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "../layout/nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "../layout/nav.module.css";
import { useSelector } from "react-redux";
import { selectProsumer } from "../../redux/reducers/userReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { IonSpinner } from "@ionic/react";
import { IMeter } from "../../types/Prosumer";
import { backend_url } from "../../utils/be";

interface ModalProps {
    show: boolean;
    handleClose: () => void;
    meter?: IMeter;
}


export const identifiers = [
    "Main meter",
    "Battery Main meter",
    "Battery Transact meter",
    "PV meter"
];

const CreateUpdateMeterModal: React.FC<ModalProps> = ({ show, handleClose, meter }) => {
    const prosumer = useSelector(selectProsumer);
    const [serialNumber, setSerialNumber] = useState(meter?.SN || "");
    const [meterIdentifier, setMeterIdentifier] = useState(meter?.dwelling_identifier || identifiers[0]);
    const [dwellingId, setDwellingId] = useState(meter?.dwelling_id || "");
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (meter) {
            setSerialNumber(meter.SN)
            setMeterIdentifier(meter.dwelling_identifier)
            setDwellingId(meter.dwelling_id)
        } else {
            setSerialNumber("")
            setMeterIdentifier(identifiers[0])
            setDwellingId("")
        }

    }, [meter]);

    const handleUpdateMeter = async (event: any) => {
        if (!serialNumber || !meterIdentifier || !dwellingId) {
            return toast.error("Please fill all fields");
        }

        const data = {
            serialNumber,
            userId: prosumer?.id,
            dwellingIdentifier: meterIdentifier,
            dwellingId,
            meterId: meter?.id || ""
        };

        setLoading(true);

        try {
            let response;
            if (meter) {
                // Update existing meter
                response = await axios.post(
                    `${backend_url}/admin/update_meter`,
                    data,
                    {
                        headers: {
                            "ngrok-skip-browser-warning": "true",
                        },
                    }
                );
            } else {
                // Create new meter
                response = await axios.post(
                    `${backend_url}/admin/register_meter`,
                    data,
                    {
                        headers: {
                            "ngrok-skip-browser-warning": "true",
                        },
                    }
                );
            }

            if (response.data.success) {
                toast.success(meter ? "Meter has been updated" : "Meter has been created");


                //clear
                setSerialNumber("");
                setMeterIdentifier(identifiers[0]);
                setDwellingId("");
                handleClose()

            }
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    };

    return (
        <Form>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="modal"
                style={{ borderRadius: "10px" }}
            >
                <Modal.Header style={{ position: "relative" }} className={styles.mhead}>
                    <Modal.Title>{meter ? "Update Meter" : "Create Meter"}</Modal.Title>
                    <div className={styles.customeClose} onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                        style={{ width: "100%" }}
                    >
                        <Form.Label>Enter Serial Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Serial Number"
                            name="Serial number"
                            className={styles.finput}
                            onChange={(e) => setSerialNumber(e.target.value.toLowerCase())}
                            value={serialNumber}
                            required
                        />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                        style={{ width: "100%" }}
                    >
                        <Form.Label>Dwelling Identifier</Form.Label>
                        <Form.Select
                            className={styles.finput}
                            onChange={(e) => setMeterIdentifier(e.target.value!)}
                            value={meterIdentifier}
                        >
                            {identifiers.map((identifier) => (
                                <option key={identifier} value={identifier}>
                                    {identifier}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                        style={{ width: "100%" }}
                    >
                        <Form.Label>Dwelling Id</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Dwelling Id"
                            name="Dwelling Id"
                            className={styles.finput}
                            onChange={(e) => setDwellingId(e.target.value.toLowerCase())}
                            value={dwellingId}
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className={styles.sbtn}
                        style={{
                            backgroundColor: "#7EDD62",
                            borderRadius: "10px",
                            border: "none",
                        }}
                        onClick={handleUpdateMeter}
                        name="submit"
                    >
                        {loading ? <IonSpinner name="dots" /> : meter ? "Update Meter" : "Create Meter"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
    );
};

export default CreateUpdateMeterModal;