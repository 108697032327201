import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./../styles/prosumer.css";
import NavBar from "../components/layout/NavBar";
import { IoAdd, IoCloseOutline, IoRepeatOutline, IoSyncOutline, IoTrashOutline } from "react-icons/io5";
import AddAdminRoleModal from "../components/Modals/AddAdminRoleModal";
import { IonAccordion, IonAccordionGroup, IonButton, IonItem, IonLabel, IonRadio, IonRadioGroup, IonToolbar } from "@ionic/react";
import { APIDataToSelectedRoles, adminRolesData } from "../utils/admin";
import axios from "axios";
import { useAdminAPI } from "../api/admin.api";
import { IAdminRole, IRole } from "../types/admin";
import AdminRoleItem from "../components/Segments/AdminRoleItem";
import { useDispatch, useSelector } from "react-redux";
import { selectAdminRoles, setAdminRoles } from "../redux/reducers/userReducer";

export default function AdminRoles() {

  const [addAdminRoles, setAddAdminRoles] = useState(false)
  const [roleName, setRoleName] = useState("")
  const [currentRole, setCurrentRole] = useState<IAdminRole>()
  const { getAdminRoles } = useAdminAPI()
  const dispatch = useDispatch()
  const adminRoles = useSelector(selectAdminRoles)


  useEffect(() => {

    fetchRoles()
  }, [])


  async function fetchRoles() {

    dispatch(setAdminRoles([]))
    await getAdminRoles()

  }


  return (
    <React.Fragment>
      <NavBar t={3} l1="/admin/roles" />
      <div className="p-5">
        <Row className="fsearch items-center mb-3 tcontainer ml-1">
          <div className="flex items-center gap-x-4">
            <input placeholder="Role Name" name="Role" value={roleName} onChange={(e) => setRoleName(e.target.value)} className="border outline-primaryGreen p-2 rounded-md" />
            <button onClick={() => setAddAdminRoles(true)} className="bg-primaryGreen  rounded-md w-24 flex text-white items-center p-2 hover:opacity-90">
              <IoAdd className="mr-3" /> Add
            </button>
          </div>
        </Row>
        <Row className="tcontainer">
          <Col xs={12} className="table_col" style={{ borderRadius: "20px" }}>

            <div className="tcont1">
              <div className="text-2xl font-semibold py-4 flex justify-between">Roles
                <IonButton fill="clear" color={"dark"} onClick={fetchRoles}>
                  <IoSyncOutline className="text-2xl" />
                </IonButton>
              </div>
              {
                adminRoles.map(((role, index) => {

                  return (
                    <AdminRoleItem _onDelete={fetchRoles} key={index} role={role} onClick={() => { setCurrentRole(role); setRoleName(role.title); setAddAdminRoles(true) }} />
                  )
                }))
              }
            </div>
          </Col>
        </Row>
      </div>
      <AddAdminRoleModal
        roleData={currentRole}
        setRoleName={(value) => setRoleName(value)}
        roleName={roleName} isOpen={addAdminRoles}
        onDidDismiss={(shouldFetch) => {
          setCurrentRole(undefined);
          setRoleName("");
          setAddAdminRoles(false);
          console.log(shouldFetch)
          shouldFetch && fetchRoles();
        }} />
    </React.Fragment>
  );
}
