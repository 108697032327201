import { IonActionSheet, IonButton, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react'
import React, { useState } from 'react'
import { IoAdd, IoClose } from 'react-icons/io5'
import CreateUpdateMeterModal from '../../Modals/AddMeterModal'



const AddMeterButton: React.FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
    const [addNew, setAddNew] = useState(false)

    return (
        <>
            <IonButton onClick={() => setAddNew(true)}>
                <span slot="start">
                    <IoAdd className="inline-block" />
                </span>
                <span>Add Meter</span>
            </IonButton>
            <CreateUpdateMeterModal show={addNew} handleClose={() => { setAddNew(false); onSuccess && onSuccess() }} />
        </>
    )
}

export default AddMeterButton