import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export interface NavLinkProps {
    l1: string,
    l2: string,
    l3: string,
    l4: string,
    l5: string,
    l6: string,
}


const ProsumerNavLinks: React.FC<{ props: NavLinkProps }> = ({ props, }) => {
    const location = useLocation()
    const { pathname } = location
    return (
        <div className=' flex  gap-x-2 lg:gap-x-3'>
            <NavLink to={props.l1}>
                <span className={`text-sm lg:text-md ${pathname.match("account") ? "font-bold underline underline-offset-4" : "font-normal"}  text-black mr-3 flex gap-x-1 items-center`}> Personal <span className='hidden lg:block'> / Profile data</span> </span>
            </NavLink>
            <NavLink to={props.l2}>
                <span className={`text-sm lg:text-md ${pathname.match("reading") ? "font-bold underline underline-offset-4" : "font-normal"} text-black mr-3`}>Readings</span>
            </NavLink>
            <NavLink to={props.l3}>
                <span className={`text-sm lg:text-md ${pathname.match("meters") ? "font-bold underline underline-offset-4" : "font-normal"} text-black mr-3`}>Meters</span>
            </NavLink>
            <NavLink to={props.l4}>
                <span className={`text-sm lg:text-md ${pathname.match("balances") ? "font-bold underline underline-offset-4" : "font-normal"} text-black mr-3`}>Balances</span>
            </NavLink>

            <NavLink to={props.l5}>
                <span className={`text-sm lg:text-md ${pathname.match("rewards") ? "font-bold underline underline-offset-4" : "font-normal"} text-black mr-3`}>Rewards</span>
            </NavLink>
            <NavLink to={props.l6}>
                <span className={`text-sm lg:text-md ${pathname.match("trades") ? "font-bold underline underline-offset-4" : "font-normal"} text-black mr-3`}>Trades</span>
            </NavLink>
        </div>
    )
}

export default ProsumerNavLinks