function formatTimeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    const interval = {
        day: Math.floor(seconds / 86400),
        hour: Math.floor(seconds / 3600),
        minute: Math.floor(seconds / 60)
    };

    if (interval.day > 0) {
        return date.toLocaleDateString('en-US');
    }
    if (interval.hour > 0) {
        return interval.hour === 1 ? "an hour ago" : interval.hour + " hours ago";
    }
    if (interval.minute > 0) {
        return interval.minute === 1 ? "a minute ago" : interval.minute + " minutes ago";
    }

    return "just now";
}

function convertToReadableDate(dateString) {
    const date = new Date(dateString);

    return date.toLocaleString('en-US');

    //remove the seconds


}


export { formatTimeAgo, convertToReadableDate };