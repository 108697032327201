import {
  Button,
  Col,
  Row,
} from "react-bootstrap";
import "./nav.css";
import styles from "./nav.module.css";
import { NavLink } from "react-router-dom";
import NavSettingsSection from "./NavSettingsSection";
import useConnector from "../../hooks/useConnector"
import { useSelector } from "react-redux";
import ProsumerNavLinks from "./ProsumerNavLinks.tsx";
import AdminNavLinks from "./AdminNavLinks";


export default function NavBar(props) {

  return (
    <Row className="pt-2 z-30 bg-gray-50 border-b sticky top-0 items-center">
      <div className="sm:flex justify-between items-center" >
        <div>
          {!!props.title ? props.title : props.t === 0 ? <></> : <></>}
          {props.t === 1 ? (
            <div className="flex items-center">
              <NavLink className={styles.navLink} to={props.l1}>
                <div className={"flex"}>
                  <img className="inline-block" alt="Hagedorn"
                    src={require("./../../asserts/images/Flags.svg").default}
                  />
                  <span className="ttext hidden md:block">Hagedorn</span>
                </div>
              </NavLink>
              <NavLink className={styles.navLink} to={props.l2}>
                <div className={"flex"}>
                  <img className="inline-block" alt="Benetutti"
                    src={require("./../../asserts/images/Ben.svg").default}
                  />
                  <span className="ttext hidden md:block">Benetutti</span>
                </div>
              </NavLink>
              <NavLink className={styles.navLink} to={props.l3}>
                <div className={"flex"}>
                  <img className="inline-block" alt="Brisbane"
                    src={require("./../../asserts/images/Bris.svg").default}
                  />
                  <span className="ttext hidden md:block">Bristol</span>
                </div>
              </NavLink>
            </div>
          ) : props.t === 2 ? (
            <>
              <ProsumerNavLinks props={props} />
            </>
          ) : props.t === 3 ? (
            <>
              <AdminNavLinks props={props} />
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          className="flex items-center"
        >

          <NavSettingsSection />
        </div>

      </div>
    </Row>
  );
}
