import axios from "axios";
import { IAdminRole, IAdminRoleAPI, IRole } from "../types/admin";
import { IPData } from "../types/logs";



export const adminRolesData = [
    { id: "prosumerProfile", name: "Prosumers - Profile" },
    { id: "prosumerBalance", name: "Prosumers - Balance" },
    { id: "prosumerMeters", name: "Prosumers - Meters" },
    { id: "prosumerReadings", name: "Prosumers - Readings" },
    { id: "prosumerTransactions", name: "Prosumers - Trade" },
    { id: "prosumerRewards", name: "Prosumers - Rewards" },
    { id: "tokenomics", name: "Tokenomics" },
    { id: "lemPrice", name: "LEM Price" },
    { id: "blacklistWallets", name: "Black Listed Wallets" },
    { id: "pauseContract", name: "Pause Contracts" },
    { id: "derRewards", name: "DER Rewards" },
    { id: "admins", name: "Admins" },
    { id: "tradeSettings", name: "Trade Settings" },
    { id: "prosumer2FA", name: "Prosumer 2FA" },
    { id: "logs", name: "Logs" },
];

// Convert selectedRoles to API data
export function selectedRolesToAPIData(selectedRoles: { selectedRoles: IRole[], title: string, }) {
    const apiData = {
        title: selectedRoles.title,
        prosumerProfile: getRoleValue(selectedRoles, "prosumerProfile"),
        prosumerBalance: getRoleValue(selectedRoles, "prosumerBalance"),
        prosumerMeters: getRoleValue(selectedRoles, "prosumerMeters"),
        prosumerReadings: getRoleValue(selectedRoles, "prosumerReadings"),
        prosumerTransactions: getRoleValue(selectedRoles, "prosumerTransactions"),
        prosumerRewards: getRoleValue(selectedRoles, "prosumerRewards"),
        tokenomics: getRoleValue(selectedRoles, "tokenomics"),
        lemPrice: getRoleValue(selectedRoles, "lemPrice"),
        blacklistWallets: getRoleValue(selectedRoles, "blacklistWallets"),
        pauseContract: getRoleValue(selectedRoles, "pauseContract"),
        derRewards: getRoleValue(selectedRoles, "derRewards"),
        admins: getRoleValue(selectedRoles, "admins"),
        tradeSettings: getRoleValue(selectedRoles, "tradeSettings"),
        prosumer2FA: getRoleValue(selectedRoles, "prosumer2FA"),
        logs: getRoleValue(selectedRoles, "logs"),

    };

    return apiData;
}

// Convert API data to selectedRoles
export function APIDataToSelectedRoles(apiData: IAdminRoleAPI) {
    const selectedRoles = {
        title: apiData.title,
        id: apiData.id,
        isSuperAdmin: apiData.isSuperAdmin,
        selectedRoles: adminRolesData.map((role) => ({
            id: role.id,
            name: role.name,
            value: apiData[role.id as "prosumerProfile"] || "NONE",
        })),
    };

    return selectedRoles as IAdminRole;
}

// Helper function to get value from selectedRoles by id
function getRoleValue(selectedRoles: { selectedRoles: IRole[] }, id: string) {
    const role = selectedRoles.selectedRoles.find((role) => role.id === id);
    return role ? role.value : "NONE";
}


export function setupBearerToken() {
    try {
        const u = localStorage.getItem("user");
        if (u) {
            const user = JSON.parse(u);
            axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
        }
    }
    catch (err) {
        console.log(err)
    }
}

export function setupAxiosHeader() {
    setupBearerToken();
    axios.defaults.headers.common["ip"] = localStorage.getItem("ip") || "";
    axios.defaults.headers.common["location"] = localStorage.getItem("location") || "";

    fetch("https://api.ipify.org/").then(async (res) => {
        const Ip = await res.text();
        axios.defaults.headers.common["ip"] = Ip;
        localStorage.setItem("ip", Ip);
    }).catch(console.log);


    fetch("https://ipapi.co/json/").then(async (res) => {
        const location = await res.json() as IPData;
        axios.defaults.headers.common["location"] = `${location.country_name}, ${location.city}`
        localStorage.setItem("location", `${location.country_name}, ${location.city}`);
    }).catch(console.log);


}