import React, { useState } from 'react'
import { BsBoxArrowInDownLeft, BsArchive, BsCalendar4, BsGrid } from 'react-icons/bs'
import { GiCrystalShine } from 'react-icons/gi'
import { IoCopyOutline, IoSearchOutline } from 'react-icons/io5'
import { ITradeTransaction } from '../../types/trade'
import axios from 'axios'
import { backend_url } from '../../utils/be'
import { IonSpinner, useIonLoading, useIonToast } from '@ionic/react'

const TransactionCard: React.FC<{ item: ITradeTransaction, onUpdate?: () => void }> = ({ item, onUpdate }) => {
    const [toast] = useIonToast()
    const [loading, setLoading] = useState(false)

    async function cancelTransaction() {

        setLoading(true)
        if (item.status == "open") {
            // cancel transaction
            await axios.put(`${backend_url}/user/transaction_status/${item.id}`, { status: "cancel" }).then(res => {
                if (res.data.success) {
                    toast({ message: "Transaction cancelled successfully", color: "success", duration: 4000, })
                    onUpdate && onUpdate()
                } else {
                    toast({ message: "Error occured while cancelling transaction", color: "danger", duration: 4000, })
                }
            }).catch(err => {
                toast({ message: err.response?.data?.message || err.message, color: "danger", duration: 4000, })
            })
        }
        setLoading(false)
    }

    return (
        <div className="border-[1px] mb-3  rounded-md border-gray-400 p-2 text-sm ">
            <div className="flex justify-between mb-1">
                <div className="flex gap-x-1 items-center">
                    <BsBoxArrowInDownLeft className='' />
                    <span className='font-light'>Type</span>
                </div>
                <div className="flex gap-x-1 capitalize items-center font-medium">
                    {item.type} Order
                </div>
            </div>
            <div className="flex justify-between mb-1">
                <div className="flex gap-x-1 items-center">
                    <GiCrystalShine className='' />
                    <span className='font-light'>Status</span>
                </div>
                <div className="flex capitalize gap-x-1 items-center font-medium">
                    {item.status}
                </div>
            </div>
            <div className="flex justify-between mb-1">
                <div className="flex gap-x-1 items-center">
                    <BsArchive className='' />
                    <span className='font-light'>ID</span>
                </div>
                <div className="flex gap-x-1 items-center font-medium">
                    {item.id.split("-")[0]}...{item.id.split("-")[item.id.split("-").length - 1]}
                </div>
            </div>
            <div className="flex justify-between mb-1">
                <div className="flex gap-x-1 items-center">
                    <BsCalendar4 className='' />
                    <span className='font-light'>Purchase date</span>
                </div>
                <div className="flex gap-x-1 items-center font-medium">
                    {item.createdAt}
                </div>
            </div>
            <div className="flex justify-between mb-1">
                <div className="flex gap-x-1 items-center">
                    <BsGrid className='' />
                    <span className='font-light'>Total kWh</span>
                </div>
                <div className="flex gap-x-1 items-center font-medium">
                    {item.total_kw}
                </div>
            </div>
            <div className="flex justify-between mb-1">
                <div className="flex gap-x-1 items-center">
                    <BsGrid className='' />
                    <span className='font-light'>Tx ID</span>
                </div>
                <div className="flex gap-x-1 items-center font-medium">
                    b43c3kem57...6794f2846v <IoCopyOutline /> <IoSearchOutline />
                </div>
            </div>
            <div className='py-2 text-end'>
                {item.status == "open" && <button name='button' onClick={cancelTransaction} className="p-1 rounded-md text-white px-3 bg-primaryGreen">
                    {loading ? <IonSpinner /> : " Cancel"}
                </button>}

            </div>
        </div>
    )
}

export default TransactionCard