import React from "react";
import { Button } from "react-bootstrap";
import useConnector from "../hooks/useConnector";

const ErrorPage = () => {
  const { switchNetwork } = useConnector();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "70vh",
      }}
    >
      <img src="/images/error.png" height={300} width={300} alt="error" />
      <h2>Wrong Network</h2>
      <p>Please switch to fantom testnet</p>
      <Button
        className="btn"
        size="sm"
        style={{ backgroundColor: "#7edd62", border: "none" }}
        onClick={switchNetwork}
      >
        Switch Network
      </Button>
    </div>
  );
};

export default ErrorPage;
