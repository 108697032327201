import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../styles/prosumer.css";
import PauseTable from "../components/Table/PauseTable";
import NavBar from "../components/layout/NavBar";

export default function PausePage() {
  return (
    <React.Fragment>
      <NavBar
        t={1}
        l1="/pause-contracts"
        l2="/pause-contracts"
        l3="/pause-contracts"
      />
      <Container style={{ padding: 15 }}>
        <Row className="tcontainer">
          <Col
            md={9}
            xs={12}
            className="table_col"
            style={{ border: "none", borderRadius: "20px" }}
          >
            <div className="ttitle mb-3">Pause contracts</div>
            <div className="tcard">
              <PauseTable />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
