import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./../styles/prosumer.css";
import adminsData from "../data/admins";
import AdminTable from "../components/Table/AdminsTable";
import NavBar from "../components/layout/NavBar";
import { useAdminAPI } from "../api/admin.api";
import { useSelector } from "react-redux";
import { selectAdminRoles } from "../redux/reducers/userReducer";
import { IonSpinner } from "@ionic/react";

export default function AdminsPage() {
  //create and initialize an array of prosumers with a tuple of 6 elements
  const [admins] = useState([...adminsData]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { getAdminRoles, createAdminAccount } = useAdminAPI()
  const adminRoles = useSelector(selectAdminRoles)
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState(adminRoles[0]?.id ?? "");


  const createAdmin = async (e) => {
    e.preventDefault();

    setLoading(true)
    const result = await createAdminAccount({ name, email, password, role })
    setLoading(false)
    if (result) {
      setName("")
      setEmail("")
      setPassword("")
      setRole("")
    }
  };


  useEffect(() => {

    getAdminRoles();

  }, [])


  const handleMouseEnter = (e) => {
    e.target.style.color = "#06152b";
  };
  const handleMouseLeave = (e) => {
    e.target.style.color = "white";
  };

  return (
    <React.Fragment>
      <NavBar t={3} l1="/admins-roles" />
      <div className="p-5">
        <Row className="fsearch items-center mb-3">
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Name</Form.Label>
            <Form.Control
              className="fcontrol"
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Email address</Form.Label>
            <Form.Control
              className="fcontrol"
              type="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Password</Form.Label>
            <Form.Control
              className="fcontrol"
              type="password"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 fgroup"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="flabel">Role</Form.Label>
            <select
              className="fcontrol"
              type="password"
              placeholder=""
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              {adminRoles.map((role, index) => (
                <option value={role.id} key={role.id}>
                  {role.title}
                </option>
              ))}</select>
          </Form.Group>
          <Form.Group
            className="mt-3 fgroup "
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              size="sm"
              className="btn"
              style={{
                backgroundColor: "#7edd62",
                borderRadius: "10px",
                padding: "10px, 8px, 10px, 8px,",
                border: "none",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Search
            </Button>
            <Button
              size="sm"
              className="btb"
              style={{
                backgroundColor: "#7edd62",
                borderRadius: "10px",
                padding: "10px, 8px, 10px, 8px,",
                marginLeft: "10px",
                border: "none",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={createAdmin}
            >
              {loading ? <IonSpinner name="dots" /> : "Create"}
            </Button>
          </Form.Group>
        </Row>
        <Row className="tcontainer">
          <Col xs={12} className="table_col" style={{ borderRadius: "20px" }}>
            <div className="tcont1">
              <div className="ttitle">Admins</div>
              <div className="tcard">
                <AdminTable data={admins} rowsPerPage={2} style={{ flex: 1 }} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
