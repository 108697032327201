import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import EditAdminModal from "../Modals/EditAdminModal";
import { IonContent, IonDatetime, IonFab, IonFabButton, IonHeader, IonItem, IonLabel, IonPopover, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from "@ionic/react";
import TableSkeleton from "../layout/tableSkeleton";
import { convertToReadableDate } from "../../utils/time";
import SelectTimeRange from "../Modals/SelectTimeRange";
import { ILogs } from "../../types/logs";
import { IoArrowForward } from "react-icons/io5";

const LogsTable = ({ }) => {
  const [row_num, setrow_num] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false)
  const { slice, range } = useTable([], page, row_num);
  const [currentAdmin, setCurrentAdmin] = useState()
  const [editAdmin, setEditAdmin] = useState(false)
  const [displayedLogs, setDisplayedLogs] = useState<ILogs[]>([])
  const [rangeValues, setRangeValues] = useState<number[]>([])
  const [selectValue, setSelectValue] = useState<string[]>([])
  const [queryText, setQueryText] = useState("")
  const [daysSelected, setDaysSelected] = useState(10)

  const rangeName = selectValue.length > 1 ? `${convertToReadableDate(selectValue[0]).split(',')[0]} - ${convertToReadableDate(selectValue[1]).split(',')[0]}` : "Date Range"

  const popButtonRef = useRef<HTMLDivElement>(null)
  const popRef = useRef(null)
  const [subject, setSubject] = useState("all")


  // useEffect(() => {
  //   setPage(page + 1);
  // }, [row_num]);

  useEffect(() => {

    // getAdminRoles();
    async function fetchLogs() {


      setLoading(true)
      if (subject !== "all") {
        return filterLogs()
      }

      await axios.get(`admin/get_logs/${page}/${row_num}`).then((res) => {
        if (res.data.success) {
          setDisplayedLogs([...res.data.data] as ILogs[])

          const pages = res.data.pages
          //range from pages
          const range = []
          for (let i = 1; i <= pages; i++) {
            range.push(i)
          }
          setRangeValues(range as number[])
        }
        else {
          toast.error(res.data.message)
        }
      }).catch(console.log).finally(() => setLoading(false))
    }

    fetchLogs()

  }, [row_num, page])

  async function filterLogs() {

    const query = {
      subject: subject,
      range: daysSelected > 0 ? [convertDaysToTimestamp(daysSelected), convertDaysToTimestamp(0)] : [selectValue[0], selectValue[1]]
    }
    setLoading(true)

    await axios.post(`/admin/filter_logs/${page}/${row_num}`, { ...query }).then(res => {

      console.log(res.data)
      if (res.data.success) {
        setDisplayedLogs([...res.data.data] as ILogs[])
        const pages = res.data.pages
        const range = []
        for (let i = 1; i <= pages; i++) {
          range.push(i)
        }
        setRangeValues(range as number[])
      }
    }).catch(console.log)

    setLoading(false)

  }

  //converts last n days to a timestamp equivalent to n days ago from today
  function convertDaysToTimestamp(days: number) {
    const today = new Date()
    const timestamp = today.setDate(today.getDate() - days)
    //conver timestam to look like 2024-01-16
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
  }

  async function searchText() {
    if (!queryText) return
    setLoading(true)
    await axios.post(`/admin/search_logs/${page}/${row_num}`, { queryValue: queryText }).then(res => {
      if (res.data.success) {
        setDisplayedLogs([...res.data.data] as ILogs[])
        const pages = res.data.pages
        const range = []
        for (let i = 1; i <= pages; i++) {
          range.push(i)
        }
        setRangeValues(range as number[])
      }
    }).catch(console.log);
    setLoading(false)
  }



  return (
    <Row style={{ width: "100%" }}>
      <div className="md:flex items-center gap-x-2 justify-between">
        <div className="flex items-center gap-x-2">
          <IonSearchbar value={queryText} onIonInput={(e) => { setQueryText(e.detail.value!) }} mode="ios" className="w-[400px] m-0 px-0 " />
          {queryText && <IonFabButton onClick={searchText} mode="ios" size="small">
            <IoArrowForward className="text-2xl" />
          </IonFabButton>}
        </div>
        <div className="flex items-center gap-x-2">
          <div>
            <select value={subject} onChange={(e) => setSubject(e.target.value)} className="h-10 border outline-primaryGreen px-2 rounded-md">
              <option value="all">All Users</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>

          </div>
          <div>
            <select value={daysSelected} onChange={(e) => { setDaysSelected(+e.target.value); (+e.target.value < 0) && popButtonRef.current?.click() }} className="h-10 border outline-primaryGreen px-2 rounded-md">
              <option value="10">Last 10 days</option>
              <option value="60">Last 1 month</option>
              <option value="360">Last 6 months</option>
              <option value="360">Last 1 year</option>
              <option value="-1">{rangeName}</option>
              {selectValue.length > 0 && <option value="-2">Change Range</option>}
            </select>
            <div ref={popButtonRef} id="click-trigger" ></div>
          </div>
          <div>
            <button name="filter" onClick={() => { filterLogs(); }} className="bg-primaryGreen p-2 px-4 rounded text-white active:opacity-80">Filter</button>
          </div>
        </div>

      </div>
      <SelectTimeRange onDidDismiss={() => setDaysSelected(-1)} onValue={(val) => { setSelectValue(val); }} />
      <div className="rshow text-right pr-3">
        Show{" "}
        <Form.Select
          aria-label="Select number of records"
          style={{
            width: "73px",
            height: "35px",
            display: "inline-block",
          }}
          className="ml-2 mr-2"
          // iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
          value={row_num}
          onChange={(e) => setrow_num(+e.target.value)}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </Form.Select>
        Records
      </div>
      <table className={styles.table} style={{ width: "100%", height: "60vh", overflow: 'scroll', position: 'relative' }}>
        <thead className={styles.tableRowHeader} style={{ "position": "sticky", top: "0px" }}>
          <tr>
            {/* <th className={styles.tableHeader}>Manage</th> */}
            <th className={styles.tableHeader}>Title</th>

            <th className={styles.tableHeader}>Email</th>
            <th className={styles.tableHeader}>User Id</th>
            <th className={styles.tableHeader}>IP</th>
            <th className={styles.tableHeader}>Location</th>
            <th className={styles.tableHeader}>Timestamp</th>

          </tr>
        </thead>
        <tbody style={{ width: "100%" }}>
          {displayedLogs?.map((el) => {
            let timestamp = el.updatedAt
            try {
              timestamp = convertToReadableDate(el.updatedAt)
            } catch (er) {
              console.log(er)
            }
            return (
              <tr
                style={{ width: "100%" }}
                className={styles.tableRowItems}
                key={el.id}
              >
                {/* <td className={styles.tableCell}> */}
                {/* <Dropdown className="drop">
                    <Dropdown.Toggle
                      style={{
                        cursor: "pointer",
                        background: "transparent",
                        color: "black",
                        border: "0px",
                        outline: "none",
                      }}
                      className={styles.dtoggle}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu onClick={() => { setEditAdmin(true); setCurrentAdmin(el) }}>
                      <Dropdown.Item>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                {/* </td> */}
                <td className={styles.tableCell}>{el.title}</td>
                <td className={styles.tableCell}>{el.email}</td>
                <td className={styles.tableCell}>{el.user_id}</td>
                <td className={styles.tableCell}>{el.user_ip}</td>
                <td className={styles.tableCell}>{el.user_location}</td>
                <td className={styles.tableCell}>{timestamp}</td>
              </tr>
            )
          })}
        </tbody>
        {loading && <TableSkeleton number={5} />}
      </table>
      <TableFooter range={rangeValues} slice={slice} setPage={setPage} page={page} />
      <EditAdminModal isOpen={editAdmin} adminData={currentAdmin} onDidDismiss={() => { setEditAdmin(false); setCurrentAdmin(undefined) }} />
    </Row>
  );
};

export default LogsTable;
