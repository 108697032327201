import React, { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { IMeter } from "../../types/Prosumer";
import TableSkeleton from "../layout/tableSkeleton";
import MetersTableItem from "./MetersTableItem";

const MetersTable = ({ data, rowsPerPage, loading }: { data: IMeter[], rowsPerPage: number, loading: boolean }) => {
  const [row_num, setrow_num] = useState(10);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, row_num) as { slice: IMeter[], range: any }



  useEffect(() => {
    setPage(page + 1);
  }, [row_num]);


  return (
    <Row style={{ width: "100%" }}>
      <div className="rshow text-right pr-3">
        Show{" "}
        <Form.Select
          aria-label="Select number of records"
          style={{
            width: "73px",
            height: "35px",
            display: "inline-block",
          }}
          className="ml-2 mr-2"
          // iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
          value={row_num}
          onChange={(e) => setrow_num(+e.target.value)}
        >
          <option value={10}>10</option>
          <option value={2}>25</option>
          <option value={50}>50</option>
        </Form.Select>
        Records
      </div>
      <table className={styles.table} style={{ width: "100%" }}>
        <thead className={styles.tableRowHeader}>
          {/* <tr> */}
          <th className={styles.tableHeader}>Manage</th>
          <th className={styles.tableHeader}>Type</th>
          <th className={styles.tableHeader}>Serial number</th>
          <th className={styles.tableHeader}>Wallet address</th>
          <th className={styles.tableHeader}>Balance (FTM)</th>
          <th className={styles.tableHeader}>Gas fees (FTM)</th>

          {/* </tr> */}
        </thead>
        <tbody style={{ width: "100%" }}>
          {slice.map((el, index) => (
            <MetersTableItem key={index} meter={el} />
          ))}
        </tbody>
      </table>
      {loading && <TableSkeleton number={5} />}
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </Row>
  );
};

export default MetersTable;
