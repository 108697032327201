import React, { useEffect } from 'react'
import useDRR from "./useDRR"
import useTRGY from "./useTRGY"
import useWATT from "./useWATT"
import useTRGYVault from "./useTRGYVault"
import useEnergyVault from "./useEnergyVault"
import { useDispatch } from 'react-redux'
import { updateDRRTotalSup, updateTRGYTotalSup, updateWATTTotalSup, updateDRRTradingVol, updateTRGYTradingVol, updateWATTTradingVol } from "../redux/reducers/twinergyReducer"
const useData = () => {
    const dispatch = useDispatch()
    const { getDRRTotalSupply } = useDRR()
    const { getTRGYTotalSupply } = useTRGY()
    const { getWATTTotalSupply } = useWATT()
    const { getWATTTradingVolume, getDRRTradingVolume } = useEnergyVault()
    const { getTRGYTradingVolume } = useTRGYVault()

    useEffect(() => {
        getDRRTotalSupply().then(data => dispatch(updateDRRTotalSup(data/10**18)))
        getTRGYTotalSupply().then(data => dispatch(updateTRGYTotalSup(data/10**18)))
        getWATTTotalSupply().then(data => dispatch(updateWATTTotalSup(data/10**18)))
        getWATTTradingVolume().then(data => dispatch(updateWATTTradingVol(data/10**18)))
        getDRRTradingVolume().then(data => dispatch(updateDRRTradingVol(data/10**18)))
        getTRGYTradingVolume().then(data => dispatch(updateTRGYTradingVol(data/10**18)))
    })

    return {}
}

export default useData
