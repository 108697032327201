import React, { useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    IonSpinner,
} from '@ionic/react';
import { IoClose } from 'react-icons/io5';
import { IRole, AdminRole, IAdminRoleAPI, IAdminRole } from '../../types/admin';
import { adminRolesData, selectedRolesToAPIData } from '../../utils/admin';
import { useAdminAPI } from '../../api/admin.api';



interface IAddAdminRoleModal {
    isOpen: boolean,
    onDidDismiss: (shouldFetch?: boolean) => void,
    roleName: string,
    setRoleName: (val: string) => void,
    roleData?: IAdminRole
}



const AddAdminRoleModal: React.FC<IAddAdminRoleModal> = ({ isOpen, onDidDismiss, roleName, setRoleName, roleData }) => {
    const [selectedRoles, setSelectedRoles] = useState<IRole[]>(roleData?.selectedRoles || []);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { addAdminRole, editAdminRole } = useAdminAPI()

    function updateValues(value: "VIEW" | "EDIT") {
        setSelectedRoles([...selectedRoles.map((role) => ({ ...role, value: value }))])
    }


    function updateRoleInfo(roles: IRole[], item: IRole, e: any) {
        const newRoles = roles.map((_item) => {
            return item.id === _item.id ? { ...item, value: e.detail.value } : _item;
        });
        return newRoles;
    }

    async function createUserRole(e: any) {
        e.preventDefault()

        if (!roleName || selectedRoles.length === 0) {
            setError("Role name and functionalities are required.");
            return;
        }

        setIsLoading(true);
        try {

            const newUserRole = selectedRolesToAPIData({ selectedRoles, title: roleName })

            let result: boolean | undefined | void = false;
            if (newUserRole) {
                if (!roleData) {
                    result = await addAdminRole(newUserRole as AdminRole)
                } else {
                    result = await editAdminRole(newUserRole as AdminRole, roleData?.id!)
                }
                // Reset the form and dismiss the modal
                // setRoleName("");
                setSelectedRoles([]);
                setIsLoading(false);
                setError("");
                onDidDismiss(!!result)
            }



        } catch (error) {
            setIsLoading(false);
            setError("An error occurred while creating the user role. Please try again.");
            console.error(error);
        }
    }


    function dismissModal() {
        onDidDismiss()
        setSelectedRoles([])
    }

    return (
        <IonModal onDidPresent={() => {
            roleData && setSelectedRoles(roleData.selectedRoles)
        }} isOpen={isOpen} onDidDismiss={dismissModal}>
            <IonHeader mode="md" className="ion-no-shadow px-3">
                <IonToolbar>
                    <IonTitle>{roleData ? "Edit Role" : "Create Role"}</IonTitle>
                    <IonButtons slot="end" className="">
                        <IonButton onClick={() => onDidDismiss()}>
                            <IoClose className="text-2xl" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="p-3">
                    <form onSubmit={createUserRole}>
                        <IonItem lines="full">
                            <IonInput
                                value={roleName}
                                onIonInput={(e) => setRoleName(e.detail.value!)}
                                label="Role Name"
                                labelPlacement="floating"
                                required
                            />
                        </IonItem>
                        <IonItem>
                            <IonSelect
                                value={selectedRoles.map((role) => role.id)}
                                onIonChange={(e) => {

                                    let matchingRoles = adminRolesData.filter((item) => (e.detail.value as string[]).find((text) => text === item.id));
                                    if (e.target.value == "all") {
                                        matchingRoles = adminRolesData;
                                    }
                                    const matchingRolesWithValues = matchingRoles.map((role) => {
                                        const foundSelectMatch = selectedRoles.find((selectedRole) => selectedRole.id == role.id)
                                        return foundSelectMatch ?? role;
                                    })
                                    setSelectedRoles([...matchingRolesWithValues]);
                                }}
                                multiple
                                label="Functionalities"
                                labelPlacement="floating"
                            >
                                <IonSelectOption value='all'>Sellect All</IonSelectOption>
                                {adminRolesData.map((item) => (
                                    <IonSelectOption value={item.id} key={item.id}>
                                        {item.name}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonToolbar className="mt-4" color="light">
                            <p className="p-3 ">
                                <IonLabel className='flex justify-between items-center'>Define Access
                                    <IonButtons>
                                        <IonButton onClick={() => updateValues("EDIT")} size='small'> Edit All </IonButton>
                                        <IonButton onClick={() => updateValues("VIEW")} size='small'>View All</IonButton>
                                    </IonButtons>
                                </IonLabel>
                                <IonToolbar color="light">
                                    {selectedRoles.map((item) => (
                                        <IonToolbar className="border-b" color="none" key={item.id}>
                                            <span className="text-gray-600">{item.name}</span>
                                            <IonRadioGroup
                                                slot="end"
                                                className="flex gap-x-2 text-sm"
                                                value={item.value}
                                                onIonChange={(e) => setSelectedRoles((roles) => updateRoleInfo(roles, item, e))}
                                            >
                                                <IonRadio value="EDIT" />
                                                <IonLabel>Edit</IonLabel>
                                                <IonRadio value="VIEW" />
                                                <IonLabel>View</IonLabel>
                                            </IonRadioGroup>
                                        </IonToolbar>
                                    ))}
                                </IonToolbar>
                            </p>
                        </IonToolbar>
                        <IonToolbar mode="ios" color="none" className="mt-6">
                            <IonButton disabled={roleData?.isSuperAdmin} type="submit" expand="block">
                                {isLoading ? <IonSpinner name="dots" /> : roleData ? "Edit" : "Add"}
                            </IonButton>
                        </IonToolbar>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </form>
                </div>
            </IonContent>
        </IonModal >
    );
};

export default AddAdminRoleModal;