import { IonPopover, IonHeader, IonToolbar, IonTitle, IonContent, IonSegment, IonSegmentButton, IonDatetime, IonButton, IonItem, IonButtons, useIonToast, IonModal, IonLabel, IonInput, IonPicker } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import { IoCalendar, IoCalendarOutline, IoClose } from 'react-icons/io5'

const SelectTimeRange: React.FC<{ onDidDismiss?: () => void, onValue: (val: string[]) => void, isOpen?: boolean }> = ({ isOpen, onDidDismiss, onValue }) => {
    const [value, setValue] = useState<string[]>([])
    const [error, seterror] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [popoverOne, setPopoverOne] = useState(false)
    const [popOverTwo, setPopOverTwo] = useState(false)

    function updateValues(values: string[]) {
        setValue(values)
    }



    return (
        <IonPopover mode='ios' trigger="click-trigger" isOpen={openModal} onDidDismiss={() => setOpenModal(false)} onDidPresent={() => setOpenModal(true)}>
            <IonHeader>
                <IonToolbar className="px-1">
                    <IonTitle>Select Range</IonTitle>
                    <IonButtons slot='end'>
                        <IonButton onClick={() => { setOpenModal(false); setValue([]); onDidDismiss && onDidDismiss() }}>
                            <IoClose className='text-2xl' />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <section className='py-9'>
                    <IonItem button id='click-from' onClick={() => setPopoverOne(true)}  >
                        <IonLabel>
                            From
                        </IonLabel>
                        <p className='text-sm text-gray-500 mr-2'>
                            {value[0] ? value[0] : "Select date"}
                        </p>
                        <span slot='end'><IoCalendarOutline /></span>
                    </IonItem>
                    <SelectDatePopover showPopover={popoverOne} setshowPopover={() => setPopoverOne(false)} value={value[0]} onSelected={(val) => val && updateValues([val, value[1]])} trigger="click-from" />

                    <IonItem button id='click-to' onClick={() => setPopOverTwo(true)}>
                        <IonLabel>
                            To
                        </IonLabel>
                        <p className='text-sm text-gray-500 mr-2'>
                            {value[1] ? value[1] : "Select date"}
                        </p>
                        <span slot='end'><IoCalendarOutline /></span>
                    </IonItem>

                    <SelectDatePopover showPopover={popOverTwo} setshowPopover={() => setPopOverTwo(false)} value={value[1]} onSelected={(val) => val && updateValues([value[0], val])} trigger="click-to" />
                </section>
                <IonItem>
                    <IonButtons slot='end'>
                        <IonButton onClick={() => updateValues([])} >Reset</IonButton>
                        <IonButton onClick={() => { onValue(value.sort()); setOpenModal(false); onDidDismiss && onDidDismiss() }} color={'success'}>Done</IonButton>
                    </IonButtons>
                </IonItem>
            </IonContent>
        </IonPopover>
    )
}

export default SelectTimeRange


function SelectDatePopover({ value, onSelected, trigger, showPopover, setshowPopover }: { value: string, onSelected: (val: string) => void, trigger: string, showPopover: boolean, setshowPopover: (val: boolean) => void }) {

    return (
        <>
            {showPopover && <IonPopover isOpen trigger={trigger}>
                <div>
                    <IonDatetime presentation='date' value={value} onIonChange={(e) => onSelected(e.detail.value as string)} ></IonDatetime>
                    <IonItem>
                        <IonButtons slot='end'>
                            <IonButton onClick={() => { onSelected(""); setshowPopover(false) }}>Cancel</IonButton>
                            <IonButton color={'success'} onClick={() => setshowPopover(false)}>Done</IonButton>
                        </IonButtons>
                    </IonItem>
                </div>
            </IonPopover>}
        </>

    )

}