import React, { useEffect, useState } from 'react'
import NavSettingsSection from '../components/layout/NavSettingsSection'
import { Card, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import DERRewardsTable from '../components/Table/DERRewardTable'
import "./../styles/prosumer.css";
import CreateDERRewardModal from '../components/Modals/CreateDERRewardModal'
import axios from 'axios'
import { DRRReward } from '../types/Rewards'
import ClaimRewardModal from '../components/Modals/CreateDERRewardModal'
import { toast } from 'react-toastify'
import { backend_url } from '../utils/be'

export const der_baseurl = backend_url + "/derr/"

const DERRewards = () => {


    const [createReward, setCreateReward] = useState(false)
    const [queryTitle, setQueryTitle] = useState("")
    const [queryStatus, setQueryStatus] = useState("All")
    const [rewards, setRewards] = useState<DRRReward[]>([])
    const [tempRewards, setTempRewards] = useState<DRRReward[]>([])

    useEffect(() => {
        getRewards()
    }, [])

    async function getRewards() {
        const headers: any = {
            'ngrok-skip-browser-warning': true,
        }

        axios.get(`${der_baseurl}/get_rewards`, { headers }).then((res) => {
            if (res.data.success) {
                setRewards(res.data.data)
                setTempRewards(res.data.data)
            }
        }).catch((err) => {
            toast.error(err.message);
        })
    }

    function filterRewards() {
        let temp = rewards.filter((reward) => {
            if (queryStatus === "All") {
                return reward.title.toLowerCase().includes(queryTitle.toLowerCase())
            } else {
                return reward.title.toLowerCase().includes(queryTitle.toLowerCase()) && reward.status === queryStatus
            }
        })
        setTempRewards(temp)

    }


    return (
        <Row className="" >
            <div className="grid grid-cols-2 p-3 py-2  z-30 bg-gray-50 border-b sticky top-0">
                <div>
                    <NavLink to={"/DER-rewards"} className={"text-2xl mr-5 text-black no-underline "}>
                        <span className='hover:text-green-500 decoration-1 underline underline-offset-2'> DER rewards</span>
                    </NavLink>
                    <NavLink to={"/DER-claims"} className={"text-2xl text-black  no-underline"}>
                        <span className='hover:text-green-500'>Claims</span>
                    </NavLink>
                </div>
                <div className="flex items-center justify-end">
                    <NavSettingsSection />
                </div>
            </div>

            <Row style={{ padding: "0px 6px 0px 20px" }}>
                {/* --- form --  */}
                <div className="h-10"></div>
                <div className="mt-10"  >
                    <div className="grid lg:grid-cols-2 gap-y-5">
                        <div className="flex items-center space-x-3 text-gray-500 font-light">
                            <div>
                                <label >Title</label>
                                <div className='mt-2'>
                                    <input value={queryTitle} onChange={(e) => setQueryTitle(e.target.value)}
                                        type="text" className='border w-48 rounded-xl outline-yellow-500 py-2 px-2' name='title' placeholder='Title' />
                                </div>
                            </div>
                            <div>
                                <label >Status</label>
                                <div className='mt-2   border rounded-xl font-light w-48 outline-yellow-500 py-2 px-2'>
                                    <select name='Query' value={queryStatus} onChange={(e) => setQueryStatus(e.target.value)}
                                        className='w-100 outline-none' >
                                        <option value="All">All</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex  lg:justify-end '>
                            <div className='relative flex flex-col justify-between'>
                                <div></div>
                                <div className=' w-100 space-x-3'>
                                    <button onClick={filterRewards} className='rounded-md bg-green-500 p-[10px] text-white w-32 '>
                                        Search
                                    </button>
                                    <button onClick={() => setCreateReward(true)} className='rounded-md bg-green-500 p-[10px] text-white w-32 '>
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='p-6'>
                    <div className='mt-10 tcontainer shadow-lg rounded-3xl' style={{ padding: "20px 40px" }}>
                        <h2 className='text-xl font-medium'>DER Rewards</h2>
                        <div>
                            <DERRewardsTable onChange={() => { getRewards() }} data={tempRewards} rowsPerPage={20} />
                        </div>
                    </div>

                </div>

            </Row>
            <CreateDERRewardModal show={createReward} handleClose={() => setCreateReward(false)} />
        </Row>
    )
}

export default DERRewards