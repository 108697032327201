import React, { useEffect, useState } from 'react'
import NavBar from '../components/layout/NavBar'
import { Row } from 'react-bootstrap'
import { IoShareOutline } from 'react-icons/io5'
import twinergyIcon from "../asserts/images/twinergyIcon.png"
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { HiMiniBolt } from 'react-icons/hi2'
import { AiOutlineGift } from 'react-icons/ai'
import useEnergyVault from '../hooks/useEnergyVault'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import useTRGY from '../hooks/useTRGY'
import useConnector from '../hooks/useConnector'
import useDRR from '../hooks/useDRR'
import { Prosumer, selectProsumer } from '../redux/reducers/userReducer'
import { IonSpinner } from '@ionic/react'
import ProsumerDetailSection from '../components/Prosumer/ProsumerDetailSection'

const BalancesPage = () => {
    const { wallet } = useSelector((selectProsumer)) as Prosumer;
    const [loading, setLoading] = useState(false)
    const [wattBalance, setWattBalance] = useState<number>()
    const [trgBalance, setTRGBalance] = useState<number>()
    const [ftmBalance, setFTMBalance] = useState<number>()
    const [drrBalance, setDRRBalance] = useState<number>()

    const { getWATTBalance, contract } = useEnergyVault()
    const { getTRGBalance, TRG } = useTRGY()
    const { getFTMBalance, provider } = useConnector()
    const { getDRRBalance, DRR } = useDRR()

    useEffect(() => {
        try {
            getReadings()
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }, [wallet, contract, TRG, provider, DRR])

    async function getReadings() {
        setLoading(true)

        await getWATTBalance(wallet).then((res) => {
            setWattBalance(res)
        });
        await getTRGBalance(wallet).then((res) => {
            setTRGBalance(res)
        });
        await getFTMBalance(wallet).then((res) => {
            setFTMBalance(res)
        });
        await getDRRBalance(wallet).then((res) => {
            setDRRBalance(res)
        });

        setLoading(false)
    }

    return (
        <>
            <NavBar t={2} l1="/account-data" l2="/readings" l3="/meters" l4="/balances" l5="/rewards" l6="/trades" />
            <ProsumerDetailSection />
            <Row className=''>
                <div className="p-3">
                    <h3 className='text-3xl my-4 font-medium'>Readings {loading && <IonSpinner />}</h3>
                    <div className="grid md:grid-cols-2 gap-4">
                        <section className="shadow-md border-[1px] border-gray-100 rounded-xl  p-4">
                            <div className="flex text-xl justify-between">
                                <h2 className='text-2xl font-medium'>TRGY</h2>
                                <BsBoxArrowUpRight />
                            </div>
                            <div className="border-b py-2 text-xs text-gray-500">
                                Twinergy Token
                            </div>
                            <div className='my-4 border-1 border-gray-500 items-center flex rounded-md gap-x-2 p-1 px-2'>
                                <img src={twinergyIcon} alt="twinergy icon" className='h-5' /> <span className='text-md'>{trgBalance}</span>
                            </div>
                        </section>
                        <section className=" shadow-md border-[1px] border-gray-100 rounded-xl  p-4">
                            <div className="flex text-xl justify-between">
                                <h2 className='text-2xl font-medium'>WATT</h2>
                                <BsBoxArrowUpRight />
                            </div>
                            <div className="border-b py-2 text-xs text-gray-500">
                                kWh token
                            </div>
                            <div className='my-4 border-1 border-gray-500 items-center flex rounded-md gap-x-2 p-1 px-2'>
                                <HiMiniBolt className="text-primaryGreen" /> <span className='text-md'>{wattBalance}</span>
                            </div>
                        </section>
                        <section className=" shadow-md border-[1px] border-gray-100 rounded-xl  p-4">
                            <div className="flex text-xl justify-between">
                                <h2 className='text-2xl font-medium'>DRR</h2>
                                <BsBoxArrowUpRight />
                            </div>
                            <div className="border-b py-2 text-xs text-gray-500">
                                Demand response reward token
                            </div>
                            <div className='my-4 border-1 border-gray-500 items-center flex rounded-md gap-x-2 p-1 px-2'>
                                <AiOutlineGift className="text-primaryGreen" /> <span className='text-md'>{drrBalance}</span>
                            </div>
                        </section>
                        <section className=" shadow-md border-[1px] border-gray-100 rounded-xl  p-4">
                            <div className="flex text-xl justify-between">
                                <h2 className='text-2xl font-medium'>FTM</h2>
                                <BsBoxArrowUpRight />
                            </div>
                            <div className="border-b py-2 text-xs text-gray-500">
                                Fantom token
                            </div>
                            <div className='my-4 border-1 border-gray-500 items-center flex rounded-md gap-x-2 p-1 px-2'>
                                <div className='w-3' /> <span className='text-md'>{ftmBalance}</span>
                            </div>
                        </section>
                    </div>
                </div>

            </Row>
        </>
    )
}

export default BalancesPage