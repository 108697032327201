import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IAdmin, IAdminRole, IRole } from "../../types/admin";


const u = localStorage.getItem("user");
const p = localStorage.getItem("prosumer-data");
const r = localStorage.getItem("admin-roles");
const a = localStorage.getItem("admins");

export interface UserState {
  id: string,
  name: string,
  email: string,
  password: string,
  token: string,
  fa_key: string,
  role: string

}

export interface Prosumer {
  client_id: string,
  country: string,
  createdAt: string,
  dwelling_id: string,
  email: string,
  family_name: string,
  id: string,
  id_number: string,
  language: string,
  name: string,
  phone: string,
  pilot_id: string,
  postal_address: string,
  updatedAt: string,
  wallet: string,
  fa_key: string,



}


interface UserSlice {
  user: UserState | null,
  prosumer: Prosumer | null,
  roles: IAdminRole[] | [],
  admins: IAdmin[] | []
}


const initialState: UserSlice = {
  user: u ? JSON.parse(u) : null,
  prosumer: p ? JSON.parse(p) : null,
  roles: r ? JSON.parse(r) : [],
  admins: []

};

export const userSlice = createSlice({
  name: "user_details",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setProsumer: (state, action: PayloadAction<Prosumer>) => {
      state.prosumer = action.payload;
      localStorage.setItem("prosumer-data", JSON.stringify(action.payload));
    },

    setAdminRoles: (state, action: PayloadAction<IAdminRole[]>) => {
      state.roles = action.payload;
      localStorage.setItem("admin-roles", JSON.stringify(action.payload));
    },
    setAdmins: (state, action: PayloadAction<IAdmin[]>) => {
      state.admins = action.payload;
      localStorage.setItem("admins", JSON.stringify(action.payload));
    },


  },
});

// Action creators are generated for each case reducer function
export const { setUser, setProsumer, setAdminRoles, setAdmins } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export const selectProsumer = (state: RootState) => state.user.prosumer;

export const selectAdminRoles = (state: RootState) => state.user.roles;

export const selectAdmins = (state: RootState) => state.user.admins;

export default userSlice.reducer;
