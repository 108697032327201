import React, { useState, useEffect } from "react";
import { Dropdown, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { IClaim, RewardHistory } from "../../types/Rewards";
import { IonToggle } from "@ionic/react";
import axios from "axios";
import { der_baseurl } from "../../pages/DERRewards";
import { toast } from "react-toastify";
import ClaimRow from "./ClaimRow";

const DERClaimsTable = ({ data, rowsPerPage }: { data: IClaim[], rowsPerPage: number }) => {
    const [row_num, setrow_num] = useState(10);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, row_num) as { slice: IClaim[], range: number[] }
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setPage(page + 1);
        console.log(slice)
    }, [row_num]);


    return (
        <Row style={{ width: "100%" }}>
            <div className="rshow text-right pr-3">
                Show{" "}
                <Form.Select
                    aria-label="Select number of records"
                    style={{
                        width: "73px",
                        height: "35px",
                        display: "inline-block",
                    }}
                    className="ml-2 mr-2"
                    //   iconComponent={<FontAwesomeIcon icon={faEllipsisV} />}
                    value={row_num}
                    onChange={(e) => setrow_num(+e.target.value!)}
                >
                    <option value={10}>10</option>
                    <option value={2}>25</option>
                    <option value={50}>50</option>
                </Form.Select>
                Records
            </div>
            <table className={styles.table} style={{ width: "100%" }}>
                <thead className={styles.tableRowHeader} >
                    {/* <tr> */}
                    <th className={styles.tableHeader}>Username</th>
                    <th className={styles.tableHeader}>Title</th>
                    <th className={styles.tableHeader}>Timestamp</th>
                    <th className={styles.tableHeader}>Email Address</th>
                    <th className={styles.tableHeader}>Mark as Issued</th>
                    <th className={styles.tableHeader}>Status</th>

                    {/* </tr> */}
                </thead>
                <tbody style={{ width: "100%" }}>
                    {slice.map((el) => (
                        <ClaimRow el={el} />
                    ))}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </Row>
    );
};

export default DERClaimsTable;



