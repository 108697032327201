import axios from "axios";
import { Prosumer } from "../redux/reducers/userReducer";
import { IMeter } from "../types/Prosumer";
import { meterNameToDescription } from "../utils/meters";
import useMeters from "../hooks/useMeters";
import { useIonToast } from "@ionic/react";




export function useProsumerAPI() {

    const [toast] = useIonToast()
    const { getReadings, contract } = useMeters();


    async function getAllProsumers() {

        return axios
            .get("/admin/users")
            .then((result) => {
                if (result.data.success) {
                    return result.data.data as Prosumer[] || []
                } else {
                    toast({ message: result.data.message, duration: 2000, color: 'warning' });
                }
            }).catch((err) => {
                console.log(err)
                toast({ message: "Failed to get prosumers. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
            });
    }

    async function getProsumerReadings(prosumer: Prosumer) {

        return axios
            .get(`/meter/get_meters/${prosumer?.id}`)
            .then(async (response) => {

                if (!response.data.success) {
                    toast({ message: response.data.message, duration: 2000, color: 'warning' });
                    return []
                }

                const meters = response.data as IMeter[];
                let _meters: any = [];

                for (let meter of meters) {
                    let _readings: any = null;

                    if (meter.dwelling_identifier === "Battery Transact meter") {
                        _readings = await getReadings(meter.wallet_address, true);
                    } else {
                        _readings = await getReadings(meter.wallet_address, false);
                    }
                    _meters = [
                        ..._meters,
                        {
                            ...meter,
                            description: meterNameToDescription(meter.dwelling_identifier),
                            ..._readings,
                        }];
                }
                return (_meters);

            }).catch((err) => {
                console.log(err)
                toast({ message: "Failed to get prosumer readings. Please try again!", duration: 4000, color: 'danger', buttons: [{ text: 'OK' }] });
            })
    }

    return {
        getAllProsumers,
        getProsumerReadings
    }
}