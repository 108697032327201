import React, { useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import NavBar from "../components/layout/NavBar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Prosumer, selectProsumer, setProsumer } from "../redux/reducers/userReducer";
import { IonButton, IonSpinner, useIonToast } from "@ionic/react";
import axios from "axios";
import { backend_url } from "../utils/be";


export default function AccountDataPage() {

  const location = useLocation();
  const prosumer = useSelector(selectProsumer);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [toast] = useIonToast()

  async function disable2FA() {
    setLoading(true);

    await axios.get(`${backend_url}/admin/disable_otp/${prosumer?.id}`).then((res) => {
      if (res.data.success) {
        toast({ message: res.data.message, duration: 3000, color: "success", buttons: ['OK'] })
        dispatch(setProsumer({ ...prosumer, fa_key: "" } as Prosumer))
      } else {
        toast({ message: res.data.message, duration: 8000, color: "danger", buttons: ['OK'] })
      }
    }).catch((error) => {
      toast({ message: "Unfortunately, something went wrong", duration: 8000, color: "danger", buttons: ['OK'] })
    })

    setLoading(false)
  }


  useEffect(() => {
    if (location.state) {
      dispatch(setProsumer(location.state as Prosumer));
    }
  }, [])

  return (
    <React.Fragment>
      <NavBar t={2} l1="/account-data" l2="/readings" l3="/meters" l4="/balances" l5="/rewards" l6="/trades" />
      <Row>
        <div className="lg:grid grid-cols-2 p-5 gap-4 space-y-5">
          <div className="p-4 shadow-md rounded-xl">
            <Card.Title
              style={{
                color: "#1a1d20",
                padding: "20px 0px",
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "28px",
                lineHeight: "120%",
                width: "100%",
              }}
            >
              Personal data
            </Card.Title>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Name*</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Name"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="Elena"
                      value={prosumer?.name}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Family name*</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Family Name"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="Stanley"
                      value={prosumer?.family_name}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Phone</label>
                  <div className="py-1">
                    <input
                      type="tel"
                      name="Phone"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="00344 6837 267584"
                      value={prosumer?.phone}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Identification number or passport</label>
                  <div className="py-1">
                    <input
                      type="tel"
                      name="Identification number or passport"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="PA0940562"
                      value={prosumer?.id_number}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Postal address</label>
                  <div className="py-1">
                    <input
                      type="tel"
                      name="Postal address"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="63 Sandyhill Rd Langford, LD2 Dar, United Kingdom"
                      value={prosumer?.postal_address}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </div>
          <div className="p-4 shadow-md rounded-xl">
            <Card.Title
              style={{
                color: "#1a1d20",
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "28px",
                lineHeight: "120%",
                width: "100%",
                padding: "20px 0px",
              }}
            >
              Profile data
            </Card.Title>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Dwelling identifier*</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Dwellling identifier"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="Pilot 1"
                      value={prosumer?.dwelling_id}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Language*</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Language"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="English"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Country</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Country"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="United Kingdom"
                      value={prosumer?.country}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Username</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Username"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="Freshamc"
                      value={prosumer?.name}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label className="font-semibold">Main wallet address</label>
                  <div className="py-1">
                    <input
                      type="text"
                      name="Main wallet address"
                      className="border-[#7D7D7D] border-b w-full outline-none placeholder:font-light"
                      placeholder="0x9541...36wg"
                      value={prosumer?.wallet}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </div>
          {prosumer?.fa_key && <div className="p-4 shadow-md rounded-xl col-span-2">
            <Card.Title
              style={{
                color: "#1a1d20",
                padding: "20px 0px",
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "28px",
                lineHeight: "120%",
                width: "100%",
              }}
            >
              Settings
            </Card.Title>
            <Card.Body>
              <div className="md:p-5 flex-col md:flex-row flex  md:items-center gap-x-4 justify-between ">
                <span>Disable 2FA for this Prosumer</span>
                <IonButton
                  mode="ios"
                  onClick={disable2FA}
                  color={'success'}
                >
                  {loading ? <IonSpinner name="dots" /> : "Disable 2FA"}
                </IonButton>
              </div>
            </Card.Body>
          </div>}

        </div>
      </Row>
    </React.Fragment>
  );
}