/* eslint-disable no-loop-func */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import energy_vault from "../contracts/energy_vault.json";
import { toast } from "react-toastify";

const useEnergyVault = () => {
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const [egy_paused, setERGPaused] = useState(false);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(
        new ethers.Contract(config.energy_vault, energy_vault, signer)
      );
    }
  }, [provider]);

  useEffect(() => {
    contract
      ?.paused()
      .then(data => {
        setERGPaused(data);
      })
      .catch(err => console.error(err));
  }, [contract]);

  const getWATTTradingVolume = async () => {
    let volume = await contract?.wattTradingVolume();
    return Number(volume?._hex);
  };

  const getDRRTradingVolume = async () => {
    let volume = await contract?.drrTradingVolume();
    return Number(volume?._hex);
  };

  const pauseEnergyVault = async () => {
    try {
      await contract?.pauseVault();
      toast.success("Energy Vault Paused !!");
    } catch (error) {
      toast.error("Operation error !!");
    }
  };
  const unPauseEnergyVault = async () => {
    try {
      await contract?.unPauseVault();
      toast.success("Energy Vault Unpaused !!");
    } catch (error) {
      toast.error("Operation error !!");
    }
  };

  const getWATTBalance = async (address) => {
    let total = await contract?.wattUserBalance(address)
    const balanceEth = ethers.utils.formatEther(total);
    return +balanceEth;
  }



  return {
    getWATTTradingVolume,
    getDRRTradingVolume,
    pauseEnergyVault,
    unPauseEnergyVault,
    egy_paused,
    setERGPaused,
    getWATTBalance,
    contract
  };
};

export default useEnergyVault;
