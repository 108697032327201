import React, { useEffect, useState } from 'react'
import NavSettingsSection from '../components/layout/NavSettingsSection'
import { Card, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import DERRewardsTable from '../components/Table/DERRewardTable'
import "./../styles/prosumer.css";
import DERClaimsTable from '../components/Table/DERClaimsTable'
import axios from 'axios'
import { der_baseurl } from './DERRewards'
import { toast } from 'react-toastify'
import { IClaim, RewardHistory } from '../types/Rewards'


const DERClaims = () => {
    const [claims, setClaims] = useState<IClaim[]>([])

    useEffect(() => {

        async function getClaims() {
            const headers: any = {
                'ngrok-skip-browser-warning': true,
            };

            axios.get(`${der_baseurl}/get_claims`, { headers }).then((res) => {
                if (res.data.success) {
                    // console.log(res.data.data);
                    setClaims([...res.data.data.reverse()]);
                }
            }).catch((err) => {
                toast.error(err.message);
            })
        }
        getClaims();


    }, [])


    return (
        <Row className="" >
            <div className="grid grid-cols-2 p-3  z-30 bg-gray-50 border-b sticky top-0">
                <div>
                    <NavLink to={"/DER-rewards"} className={"text-2xl mr-5 text-black no-underline "}>
                        <span className='hover:text-green-500 '> DER rewards</span>
                    </NavLink>
                    <NavLink to={"/DER-claims"} className={"text-2xl text-black  no-underline"}>
                        <span className='hover:text-green-500 decoration-1 underline underline-offset-2'>Claims</span>
                    </NavLink>
                </div>
                <div className="flex items-center justify-end">
                    <NavSettingsSection />
                </div>
            </div>

            <Row style={{ padding: "20px 30px" }}>

                <div className='p-6'>
                    <div className='mt-10 tcontainer shadow-lg rounded-3xl' style={{ padding: "20px 40px" }}>
                        <h2>Claims</h2>
                        <div>
                            <DERClaimsTable data={claims} rowsPerPage={20} />
                        </div>
                    </div>
                </div>

            </Row>

        </Row>
    )
}

export default DERClaims