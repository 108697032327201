import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config  from "../config";
import drr from "../contracts/drr.json";

const useDRR = () => {
    const { provider } = useConnector();
    const [contract, setContract] = useState(null);
  
    useEffect(() => {
      if (provider) {
        const signer = provider.getSigner();
        setContract(new ethers.Contract(config.DRR, drr, signer));
      }
    }, [provider]);
     
    const getDRRTotalSupply = async () => {
        let total  =  await contract?.totalSupply()
        return Number(total?._hex)
    }


    const getDRRBalance = async (address) => {
      let total  =  await contract?.balanceOf(address)
      return Number(total?._hex)
     }
     
    return {getDRRTotalSupply, getDRRBalance, DRR:contract}
}

export default useDRR
