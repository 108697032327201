import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import meter_reading from "../contracts/meter_reading.json";

const useMeters = () => {
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(
        new ethers.Contract(config.meter_reading, meter_reading, signer)
      );
    }
  }, [provider]);

  const getReadings = async (wallet_address, isTransactMeter) => {
    if (!isTransactMeter) {
      let count = await contract?.counts(wallet_address);
      if(+count._hex === 0) return  {
        input: 0,
        output:0,
      };;
      let readings = await contract?.readings(wallet_address, Number(count._hex) - 1);
      return {
          input: readings[1]/1000,
          output: readings[2]/1000,
        };
    } else {
        let readings = await contract?.transactionReadings(wallet_address);

      return {
        input: (readings[1]._hex/10**18),
        output: (readings[2]._hex/10**18),
      };
    }
  };

  return {
    getReadings,
    contract
  };
};

export default useMeters;
