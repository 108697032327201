import React from "react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import "./pause.css";
import styles from "./Table.module.css";
import useTRGYVault from "../../hooks/useTRGYVault";
import config from "../../config";
import useEnergyVault from "../../hooks/useEnergyVault";
import { copyAddress } from "../../utils/clipboard";
import useOrderBook from "../../hooks/useOrderBook";

const PauseTable = () => {
  const { pauseTRGYVault, unPauseTRGYVault, trg_paused, setTRGPaused } =
    useTRGYVault();
  const { pauseEnergyVault, unPauseEnergyVault, egy_paused, setERGPaused } =
    useEnergyVault();
    const {pauseBook, unPauseBook, book_paused, setBookPaused} = useOrderBook()

  const handlePauseTRGVault = async () => {
    if (trg_paused) {
      await unPauseTRGYVault();
      setTRGPaused(false);
    } else {
      await pauseTRGYVault();
      setTRGPaused(true);
    }
  };

  const handlePauseEnergyVault = async () => {
    if (egy_paused) {
      await unPauseEnergyVault();
      setERGPaused(false);
    } else {
      await pauseEnergyVault();
      setERGPaused(true);
    }
  };

  const handlePauseOrderBook = async () => {
    if (book_paused) {
      await unPauseBook();
      setBookPaused(false);
    } else {
      await pauseBook();
      setBookPaused(true);
    }
  };

  return (
    <Row style={{ width: "100%" }}>
      <table className={styles.table} style={{ width: "100%" }}>
        <thead className={styles.tableRowHeader}>
          {/* <tr> */}
          <th className={styles.tableHeader}>Title</th>
          <th className={styles.tableHeader}>Contract address</th>
          <th className={styles.tableHeader}>Pause</th>

          {/* </tr> */}
        </thead>
        <tbody style={{ width: "100%" }}>
          <tr style={{ width: "100%" }} className={styles.tableRowItems}>
            <td className={styles.tableCell}>TRGY vault</td>
            <td className={styles.tableCell} style={{ position: "relative" }}>
              ${config.trgy_vault.slice(0, 7)}...
              {config.trgy_vault.slice(38, 42)}
              <FontAwesomeIcon
                icon={faCopy}
                className={styles.copy_icon}
                onClick={() => copyAddress(config.trgy_vault)}
              />
            </td>
            <td className={styles.tableCell}>
              <Switch
                checked={trg_paused}
                onChange={handlePauseTRGVault}
                onColor="rgba(0, 255, 0, 0.5)"
                offColor="rgba(0, 255, 0, 0.5)"
                checkedIcon={<div style={{ backgroundColor: "#B30802" }} />}
                uncheckedIcon={<div style={{ backgroundColor: "#7EDD62" }} />}
                offHandleColor="#7EDD62"
                onHandleColor="#B30802"
                className="switch"
              />
            </td>
          </tr>
          <tr style={{ width: "100%" }} className={styles.tableRowItems}>
            <td className={styles.tableCell}>Energy vault</td>
            <td className={styles.tableCell} style={{ position: "relative" }}>
              ${config.energy_vault.slice(0, 7)}...
              {config.energy_vault.slice(38, 42)}
              <FontAwesomeIcon
                icon={faCopy}
                className={styles.copy_icon}
                onClick={() => copyAddress(config.energy_vault)}
              />
            </td>
            <td className={styles.tableCell}>
              <Switch
                checked={egy_paused}
                onChange={handlePauseEnergyVault}
                onColor="#fff00"
                offColor="#fff00"
                checkedIcon={<div style={{ backgroundColor: "#B30802" }} />}
                uncheckedIcon={<div style={{ backgroundColor: "#7EDD62" }} />}
                offHandleColor="#7EDD62"
                onHandleColor="#B30802"
                className="switch"
              />
            </td>
          </tr>
          <tr style={{ width: "100%" }} className={styles.tableRowItems}>
            <td className={styles.tableCell}>Pause Orderbook</td>
            <td className={styles.tableCell} style={{ position: "relative" }}>
              ${config.order_book.slice(0, 7)}...
              {config.order_book.slice(38, 42)}
              <FontAwesomeIcon
                icon={faCopy}
                className={styles.copy_icon}
                onClick={() => copyAddress(config.order_book)}
              />
            </td>
            <td className={styles.tableCell}>
              <Switch
                checked={book_paused}
                onChange={handlePauseOrderBook}
                onColor="#fff00"
                offColor="#fff00"
                checkedIcon={<div style={{ backgroundColor: "#B30802" }} />}
                uncheckedIcon={<div style={{ backgroundColor: "#7EDD62" }} />}
                offHandleColor="#7EDD62"
                onHandleColor="#B30802"
                className="switch"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Row>
  );
};

export default PauseTable;
