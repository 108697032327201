import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  wattTotalSupply:"",
  drrTotalSupply:"",
  trgyTotalSupply:"",
  wattTradingVol:"",
  drrTradingVol:"",
  trgyTradingVol:""
};

export const twinergyReducer = createSlice({
  name: "TwinERGY",
  initialState,
  reducers: {
    updateAddress: (state, action) => {
      state.address = action.payload;
    },
    updateWATTTotalSup: (state, action) => {
      state.wattTotalSupply = action.payload;
    },
    updateTRGYTotalSup: (state, action) => {
      state.trgyTotalSupply = action.payload;
    },
    updateDRRTotalSup: (state, action) => {
      state.drrTotalSupply = action.payload;
    },
    updateDRRTradingVol: (state, action) => {
      state.drrTradingVol = action.payload;
    },
    updateWATTTradingVol: (state, action) => {
      state.wattTradingVol = action.payload;
    },
    updateTRGYTradingVol: (state, action) => {
      state.trgyTradingVol = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAddress,
  updateTRGYTradingVol,
  updateWATTTradingVol,
  updateDRRTradingVol,
  updateWATTTotalSup,
  updateTRGYTotalSup,
  updateDRRTotalSup
} = twinergyReducer.actions;

export default twinergyReducer.reducer;
