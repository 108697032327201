import React, { useState } from "react";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faImage, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { IoImageOutline, IoToggle } from "react-icons/io5";
import styles from "../layout/nav.module.css";
import { IonSpinner, IonToggle } from "@ionic/react";
import axios from "axios";
import { der_baseurl } from "../../pages/DERRewards";
import { DRRReward } from "../../types/Rewards";
import { toast } from "react-toastify";

interface ModalProps {
    show: boolean;
    handleClose: () => void;
}


const CreateDERRewardModal: React.FC<ModalProps> = ({ show, handleClose }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [status, setStatus] = useState(false);
    const [image, setImage] = useState<File>()
    const [imageUrl, setImageUrl] = useState("")
    const [loading, setLoading] = useState(false)


    const handleCreate = async () => {
        // Validate input fields
        if (!title || !description || !price) {
            toast("Please provide all input fields");
            return;
        }

        // Log input data
        const inputData: DRRReward = {
            title,
            description,
            price,
            status: status ? "Active" : "Inactive",
            image: image as File
        };
        //form data
        const formData = new FormData()
        formData.append("title", title)
        formData.append("description", description)
        formData.append("price", price)
        formData.append("status", !status ? "open" : "processed")
        formData.append("image", image as Blob)

        const headers: any = {
            'ngrok-skip-browser-warning': true,
            'Content-Type': 'multipart/form-data'
        }

        setLoading(true)


        await axios.post(`${der_baseurl}/create_reward`, formData, headers).then((res) => {
            console.log(res.data)
            if (res.data.success) {
                toast("Successfully created DER reward")
            } else {
                toast(res.data.message)
            }
            handleClose()
        }).catch((err) => {
            toast.error(err)
        })
        setLoading(false)

    };

    const tooltip = (
        <Tooltip id="tooltip">
            Passwords must contain a minimum of 12 characters
        </Tooltip>
    );

    const handleToggle = (e: any) => {
        setStatus(e.detail.checked);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === "title") {
            setTitle(value);
        } else if (name === "price") {
            setPrice(value);
        }
    };

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    const btnhandleMouseEnter = (e: any) => {
        e.target.style.color = "#1E2727";
    };

    const btnhandleMouseLeave = (e: any) => {
        e.target.style.color = "white";
    };


    function handleGetImage() {
        const picker = document.createElement('input');
        picker.type = 'file';
        //accept svg, png, jpeg and jpg
        picker.accept = '.svg, .png, .jpeg, .jpg';
        picker.onchange = async () => {
            const files = picker.files;
            if (files && files.length > 0) {
                setImage(files[0])
                setImageUrl(URL.createObjectURL(files[0]))
            }
        }
        picker.click()
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal"
            style={{ borderRadius: "10px" }}
        >
            <Modal.Header style={{ position: "relative" }} className={styles.mhead}>
                <Modal.Title>Create DER Reward</Modal.Title>
                <div className={styles.customeClose} onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={title}
                            style={{ borderRadius: "20px" }}
                            name="title"
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>
                            Upload Image{" "}
                            {!imageUrl && <div onClick={handleGetImage} className="cursor-pointer">
                                <IoImageOutline className="text-yellow-500 text-3xl" />
                            </div>}
                            <div>
                                {imageUrl && <img onClick={handleGetImage} src={imageUrl} className="h-20" alt="image for reward" />}
                            </div>
                        </Form.Label>

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={description}
                            style={{ borderRadius: "20px" }}
                            rows={4}
                            onChange={handleTextareaChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            style={{ borderRadius: "20px" }}
                            value={price}
                            name="price"
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <div className={styles.toggle}>
                            <IonToggle
                                checked={status}
                                color={"success"}
                                // size={30}
                                // color={status ? "#7EDD62" : "#ccc"}
                                onClick={handleToggle}
                            />
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className={styles.sbtn}
                    style={{
                        backgroundColor: "#7EDD62",
                        borderRadius: "10px",
                        border: "none",
                    }}
                    onClick={handleCreate}
                    onMouseEnter={btnhandleMouseEnter}
                    onMouseLeave={btnhandleMouseLeave}
                    name="save"
                >
                    {loading ? <IonSpinner /> : "Save"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateDERRewardModal;